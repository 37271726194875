import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import Heading from '../components/Heading/Heading';
import Space from '../components/UI/Space/Space';
import Card from '../components/Card/Card';
import classes from './pages.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { getRoles } from '../api/api';

const NotExist = () => {
    const { logout } = useAuth0();
    const [beganGetting, setGetting] = useState(false)
	
	useEffect(() => {
        if(beganGetting) {
            return;
        }
        setGetting(true);
		getRoles(true).then(r => {
			for(const role of r) {
				if(role.startsWith('CFRCard')) {
					window.location.href = '/';
				}
			}
		})
	}, [beganGetting])

    return (
        <div>
            <Layout>
                <Heading
                    title="No Access"
                    description="Your account doesn't have access to the Enrollment Portal."
                />

                <Space value="50" />

                <div className="container">
                    <div className={`${classes.Row} ${classes.Enroll__row}`}>
                        <div className={classes.Enroll__left}>
                            <Card>
                                <h4>We're sorry!</h4>

                                <p>
                                    <br/>
                                    It appears your current account is not
                                    enabled for the CFR Card Enrollment Portal.
                                    <br />
                                    <br />
                                    If you feel this is in error, please contact{' '}
                                    <a
                                        href="mailto:support@cfrcard.org"
                                        style={{ color: '#579D36' }}
                                    >
                                        support@cfrcard.org
                                    </a>
                                    <br />
                                    <br />
                                    You can also{' '}
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            color: '#579D36',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => {
                                            localStorage.removeItem(
                                                'AccessToken'
                                            );
                                            localStorage.removeItem('logo');
                                            logout({
                                                returnTo:
                                                    window.location.origin,
                                            });
                                        }}
                                    >
                                        Log Out
                                    </span>{' '}
                                    if you intended to sign in under a different
                                    account.
                                </p>
                            </Card>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default NotExist;
