import React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginButton from "../LoginButton/LoginButton";
import ProfileModal from "../ProfileModal/ProfileModal";
import { getMe } from "../../api/api";
import classes from "./ProfileDropdown.module.scss";

const ProfileDropdown = () => {
    const [show, setShow] = useState(false);
    const {user, logout, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const [showModal, setShowModal] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
  useEffect(() => {
    reloadProfileData()
  }, []);

  const openModalHandler = async () => {
    await getMe();
    setShowModal(true)
  };

  const closeModalHandler = () => {
    reloadProfileData()
    setShowModal(false)
  };

  const reloadProfileData = () => {
    getMe().then((me) => {
      setActiveUser(me)
    })
  }

  if (isLoading) {
      return <div>Loading ...</div>;
  } else {
      getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user",
      }).then(function(token) {
          if (token) {
              localStorage.setItem('AccessToken', token)
          }
      })
  }

  if (isAuthenticated) {
      return (
          <>
          {
            showModal ?
            ReactDOM.createPortal(
                <ProfileModal
                    onClose={closeModalHandler}
                    data={activeUser}
                />,
                document.querySelector("body")
            ) : ''
        }
          <Dropdown className={`account-dropdown ${classes.PD_Account} ${ show ? " " + classes.PD_Account_Active : "" }`} variant="default" align="end" onToggle={(isOpen) => {
              setShow(isOpen);
          }}>
              <Dropdown.Toggle className={classes.PD_Account_Profile} variant="none">
                  <img className={classes.PD_Account_Profile_Image} src={user.picture} alt={user.name}/>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${classes.PD_Account_Menu} ${ show ? " " + classes.PD_Account_Menu_Active : "" }`}>
                  <Dropdown.Header className={classes.PD_Account_Menu_Left}>
                      <img src={user.picture} alt={user.name} className={classes.PD_Account_Menu_Left_Img} />
                  </Dropdown.Header>
                  <Dropdown.Header className={classes.PD_Account_Menu_Right}>
                      <Dropdown.ItemText className={classes.PD_Account_Menu_Right_Item_List} as="ul">
                          <li className={classes.PD_Account_Menu_Right_Item_List_Name}>{user.name}</li>
                          <li className={classes.PD_Account_Menu_Right_Item_List_Email}>{user.email}</li>
                      </Dropdown.ItemText>
                      <Dropdown.Item href="#" className={classes.PD_Account_Menu_Right_Item} onClick={() => openModalHandler() }>My Profile</Dropdown.Item>
                      <Dropdown.Item href="#" className={classes.PD_Account_Menu_Right_Item} onClick={() => {
                          localStorage.clear();
                          logout({ returnTo: window.location.origin })
                      }}>Log Out</Dropdown.Item>
                  </Dropdown.Header>
                  
              </Dropdown.Menu>
          </Dropdown>
          </>
      );

  } else {
      return (
          <LoginButton />
      );
  }
};

export default ProfileDropdown;