import { useState, useEffect } from "react";
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import useInput from '../../hooks/useInput';
import Button from '../UI/Button/Button';
import Space from '../UI/Space/Space';
import { getSavedQueries } from '../../api/api';
// @ts-ignore
import { ErrorMessageProviderBuilder } from "../UI/Input/ErrorMessageProviderBuilder.ts";


const SaveModal = ({onSubmit, closeModal}: {onSubmit: (queryName) => Promise<void>, closeModal: () => void}) => {
    const verifier = new ErrorMessageProviderBuilder("name").isAlphanumeric().isUpTo(50).isntEmpty().addCondition(
        (value) => (alreadySaved !== null && alreadySaved.includes(value) ? `You already have a query named "${value}" please choose a different name` : false)
    );
    const [alreadySaved, setAlreadySaved] = useState<any[] | null>(null)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(() => {
        setButtonDisabled(true);
        getSavedQueries().then(saved => {
            setAlreadySaved(saved.map(s => s.name));
            setButtonDisabled(false);
        })
    }, [])

    const {
        value: name,
        hasError: nameHasError,
        inputBlurHandler: nameBlur,
        inputChangeHandler: nameChangeHandler
    } = useInput(value => {
        return verifier.passes(value);
    })

    useEffect(() => {
        console.log(verifier.build()(name))
    }, [verifier, name])
    

    const submitHandler = () => {
        if(nameHasError) {
            return;
        }
        nameBlur();
        setButtonDisabled(true);
        onSubmit(name).then(() => {
            setButtonDisabled(false)
        })
    }


    return (
        <Modal onClose={closeModal}>
            <h1 style={{width: 100 + '%', textAlign: 'center'}}>Save Query As...</h1>
            <Space value={25}/>
            <Input label={'Query Name'} value={name} onChange={nameChangeHandler} hasError={nameHasError} onBlur={nameBlur} errorMessageProvider={verifier.build()}/>
            <Space value={35}/>
            <div style={{display: 'flex', justifyContent:'center', width: 100 + '%'}}>
                <Button onClick={submitHandler} disabled={buttonDisabled}>Save Query</Button>
            </div>
        </Modal>
    )
}


export default SaveModal;