
// common.js - common functionality across all api-related modules

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getHeaders = async () => {
  while (!localStorage.getItem("AccessToken")) {
    await delay(100);
  }
  return {
    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const getBaseUrl = () => {
  return process.env.REACT_APP_API_ENDPOINT;
}