import { setDateFormat } from "../assets/helper";
import {getHeaders, getBaseUrl} from './common';

const addParamsToFetchUrl = (params, fetchURL) => {
  for (var key in params) {
    if (params.hasOwnProperty(key) && params[key] !== "" && params[key] !== undefined && params[key] !== "undefined" && 
      params[key] !== null  && params[key] !== "null") {
      fetchURL.searchParams.set(key, params[key])
    }
  }
}

const addSortingToFetchUrl = (sorting, fetchURL) => {
  if (sorting[0]) {
    if (sorting[0].id) {
      fetchURL.searchParams.set('sortBy', `${sorting[0].id}`)

      if (sorting[0].desc) {
        fetchURL.searchParams.set('sortDirection', 'desc')
      } else {
        fetchURL.searchParams.set('sortDirection', 'asc')
      }
    }
  }
}

const addPaginationToFetchUrl = (pagination, fetchURL) => {
  return addParamsToFetchUrl({perPage: pagination.pageSize, page: pagination.pageIndex + 1}, fetchURL)
}

export const fetchEnrollments = async (params, sorting, pagination) => {
  const fetchURL = new URL(
    '/api/v1/enrollments',
    getBaseUrl(),
  );

  addPaginationToFetchUrl(pagination, fetchURL)
  addParamsToFetchUrl(params, fetchURL)
  addSortingToFetchUrl(sorting, fetchURL)

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders()
  })

  let json = await response.json()

  json.items = !json.items.length
    ? []
    : json.items.map((item) => {
        var capitalizedStatus = item.status
        if (capitalizedStatus.length > 0) {
          capitalizedStatus = item.status.charAt(0).toUpperCase() + item.status.slice(1)
        }
        return {
          id: item.id,
          first: item.first,
          last: item.last,
          email: item.email,
          location: [item.location, item.location_id],
          address: [`${item.address_1} ${item.address_2 ? (', ' + item.address_2) : ''}` , `${item.city}, ${item.state} ${item.zip}`],
          status: capitalizedStatus,
          date: setDateFormat(item.submitted, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          action_id: item.id,
        };
      });

  return json
}

export const fetchEnrollmentByLocation = async (params, sorting, pagination) => {
  const fetchURL = new URL(
    '/api/v1/org/enrollments',
    getBaseUrl(),
  );

  addPaginationToFetchUrl(pagination, fetchURL)
  addParamsToFetchUrl(params, fetchURL)
  addSortingToFetchUrl(sorting, fetchURL)

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders()
  })

  let json = await response.json()

  json.items = !json.items.length
    ? []
    : json.items.map((item) => {
        var capitalizedStatus = item.status
        if (capitalizedStatus.length > 0) {
          capitalizedStatus = item.status.charAt(0).toUpperCase() + item.status.slice(1)
        }
        return {
          first: item.first,
          last: item.last,
          email: item.email,
          location: item.location,
          status: capitalizedStatus,
          date: setDateFormat(item.submitted, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          action_id: item.id,
        };
      });

  return json

}