import { useMemo } from "react";
import { fetchEnrollments } from "../../../api/fetch";
import CommonTable from "../Common/Table"
import { Box } from "@mui/system";

const EnrollmentTable = ({ bodyParams }) => {

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Confirmation',
        size: 104,
      },
      {
        accessorKey: 'first',
        header: 'First',
        size: 70,
      },
      {
        accessorKey: 'last',
        header: 'Last',
        size: 70,
      },
      // {
      //   accessorKey: 'email',
      //   header: 'Email',
      //   size: 200,
      // },
      {
        accessorKey: 'location',
        header: 'Location / ID',
        size: 200,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue()[0]}
            <Box
              sx={(theme) => ({
                opacity: 0.6,
                fontSize: '0.75rem'
              })}
            >
              {cell.getValue()[1]}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'address',
        header: 'Address / City, State',
        size: 200,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue()[0]}
            <Box
              sx={(theme) => ({
                opacity: 0.6,
                fontSize: '0.75rem',
              })}
            >
              {cell.getValue()[1]}
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Cell: ({ cell }) => (
          <Box
            compenent="span"
            sx={(theme) => ({
              backgroundColor: '#eee',
              borderRadius: '0.25rem',
              color: '#666',
              maxWidth: '9ch',
              pr: '4.5rem',
              pl: '0.5rem',
              pb: '0.25rem',
              pt: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'date',
        header: 'Submitted',
        size: 100,
      },
    ], []
  )

  return (
    <div className="container">
      <CommonTable
        columns={ columns }
        fetch={ fetchEnrollments }
        bodyParams={ bodyParams }
        entryPath={"/enrollment-detail/"}/>
    </div>
  );
};

export default EnrollmentTable;