import React, { useState, useEffect, useReducer, useRef } from "react";
import ReactDOM from "react-dom";
import is from "is_js";
import Notification from "../UI/Notification/Notification";

import PhoneRow from "./PhoneRow";
import DuplicateModal from "./DuplicateModal";
import ReviewModal from "./ReviewModal";
import WaitModal from "./WaitModal";
import SuccessModal from "./SuccessModal";
import Space from "../UI/Space/Space";
import Select from "../UI/Select/Select";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Checkbox from "../UI/Checkbox/Checkbox";
import useNotification from "../../hooks/useNotification";

import useInput from "../../hooks/useInput";
import { getEnrollments, createEnrollments, getEnrollStatus } from "../../api/api";

import CreditCard from "../../assets/images/credit_card.png";
import classes from "./EnrollForm.module.scss";
import DateInput from "../UI/Input/DateInput.tsx";
import moment from "moment/moment";
import { ErrorMessageProviderBuilder } from "../UI/Input/ErrorMessageProviderBuilder.ts";
import { useNavigate } from "react-router-dom";

const fillStates = () => {
  const statesJson = require("../../assets/json/us-states.json");

  const options = [];

  for (let key in statesJson) {
    options.push({
      value: key,
      label: statesJson[key],
      isDisabled: false,
    });
  }

  options.unshift({
    value: "0",
    label: "Select...",
    isDisabled: true,
  });

  return options;
};

const initialLocationState = {
  options: [],
  activeLocation: null,
  value: "",
  hasError: false,
};

const locationReducer = (state, action) => {
  // console.log(state, action);

  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      activeLocation: state.activeLocation,
      value: state.value,
      hasError: state.hasError,
    };
  }

  if (action.type === "SET_ACTIVE_LOCATION") {
    if (state.value && state.value !== "0")
      localStorage.setItem("defaultLocation", state.value);
    return {
      options: state.options,
      activeLocation: action.payload,
      value: state.value,
      hasError: state.hasError,
    };
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      activeLocation: state.activeLocation,
      value: action.payload,
      hasError: action.payload === "",
    };
  }

  if (action.type === "SET_VALID") {
    return {
      options: state.options,
      activeLocation: state.activeLocation,
      value: state.value,
      hasError: action.payload,
    };
  }

  if (localStorage.getItem("defaultLocation")) {
    return {
      options: state.options,
      activeLocation: state.activeLocation,
      value: localStorage.getItem("defaultLocation"),
      hasError: action.payload,
    };
  }

  return initialLocationState;
};

const initialCardState = {
  options: [],
  activeCard: null,
  value: "",
  hasError: false,
};

const cardReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      activeCard: state.activeCard,
      value: state.value,
      hasError: state.hasError,
    };
  }

  if (action.type === "SET_ACTIVE_CARD") {
    return {
      options: state.options,
      activeCard: action.payload,
      value: state.value,
      hasError: state.hasError,
    };
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      activeCard: state.activeCard,
      value: action.payload,
      hasError: action.payload === "",
    };
  }

  if (action.type === "SET_VALID") {
    return {
      options: state.options,
      activeCard: state.activeCard,
      value: state.value,
      hasError: action.payload,
    };
  }

  return initialCardState;
};

const physicalStateReducer = (state, action) => {
  if (action.type === "CHANGE") {
    return {
      value: action.payload,
      hasError: action.payload === "",
    };
  }

  if (action.type === "SET_VALID") {
    return {
      value: state.value,
      hasError: action.payload,
    };
  }

  if (action.type === "RESET") {
    return {
      value: "0",
      hasError: false,
    };
  }

  return {
    value: "",
    hasError: false,
  };
};

// disable validation for testing
const disable_validation = new URLSearchParams(window.location.search).get('disable_validation') === 'true'

const getIsPhoneValid = (phonesArr) => {
  let valid = true;

  phonesArr.forEach((item) => {
    if (item.phone_number_hasError || item.phone_type_hasError) {
      valid = false;
    }
  });

  return disable_validation ? true : valid;
};

const EnrollForm = ({ location }) => {
  const stateOptions = fillStates();
  const navigate = useNavigate();

  const [locationState, dispatchLocation] = useReducer(
    locationReducer,
    initialLocationState
  );

  const [currentCards, setCurrentCards] = useState(null);
  const [cardState, dispatchCard] = useReducer(cardReducer, initialCardState);

  const { activeLocation } = locationState;
  const { activeCard } = cardState;

  const {
    value: enteredCardNumber,
    isTouched: cardNumberTouched,
    isValid: cardNumberIsValid,
    hasError: cardNumberHasError,
    inputChangeHandler: cardNumberChangeHandler,
    inputBlurHandler: cardNumberBlurHandler,
    reset: cardNumberReset,
  } = useInput((value) => {
    if (activeCard && activeCard.requires_card_number) {
      return value.trim().length === 10 && new ErrorMessageProviderBuilder('Card Number').isLength(10, 'digits').isNumeric().passes(value);
    } else {
      
      return true;
    }
  });

  /**
   * First Name
   */
  const {
    value: firstName,
    isTouched: firstNameTouched,
    isValid: firstNameIsValid,
    isFocused: firstNameisFocused,
    hasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameReset,
  } = useInput((value) => value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm));

  /**
   * Last Name
   */
  const {
    value: lastName,
    isTouched: lastNameTouched,
    isValid: lastNameIsValid,
    isFocused: lastNameisFocused,
    hasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameReset,
  } = useInput((value) => {
    let nameWithinLength = true;
    if(firstName && value) {
      nameWithinLength = (firstName + value).length <= 24
    }
    return value.trim() !== "" && nameWithinLength && value.match(/^[a-zA-Z0-9 ]+$/gm)
  });

  /**
   * Identification Value
   */
  const {
    value: identificationValue,
    isTouched: identificationValueTouched,
    isValid: identificationValueIsValid,
    hasError: identificationValueHasError,
    inputChangeHandler: identificationValueChangeHandler,
    inputBlurHandler: identificationValueBlurHandler,
    reset: identificationValueReset,
  } = useInput((value) => disable_validation ? value : value.trim().length === 9, "", "number");

  const repeatIDErrorMessageProvider = (input) => {
    if([undefined, null].includes(input)) {
      return;
    }
    let value = input.value;

    if(value !== identificationValue) {
      return "Does not match"
    }
    return "Enter valid data"
  }

  const {
    value: identificationValue_2,
    isTouched: identificationValue_2Touched,
    isValid: identificationValue_2IsValid,
    hasError: identificationValue_2HasError,
    inputChangeHandler: identificationValue_2ChangeHandler,
    inputBlurHandler: identificationValue_2BlurHandler,
    reset: identificationValue_2Reset,
  } = useInput(
    (value) => disable_validation ? value : value.trim().length === 9 && value === identificationValue,
    "",
    "number"
  );

  const getAge = (date) => {
    const dobDate = new Date(date);
    const today = new Date();
    const age = today.valueOf() - dobDate.valueOf();
    return age
  }
  const yearInEpoch = 1000 * 60 * 60 * 24 * 365;

  const ageValidation = (date) => {
    const minAge = 14;
    const maxAge = 120;
    const age = getAge(date);

    if (age < minAge * yearInEpoch || age > maxAge * yearInEpoch) {
      return false;
    }
    return true;
  };

  // const dateErrorMessageProvider = (dateFromInput) => {
  //   if(dateFromInput.includes('-')) {
  //     return;
  //   }

  //   const datestr = dateFromInput.map(date => date.current).join('/');
  //   const ageEpoch = getAge(datestr)

  //   let age = Math.floor(ageEpoch / yearInEpoch);

  //   if(!ageValidation(datestr)) {
  //     return (<span><br/> Age is {age > 0 ? age : "0"}, must be at least 14</span>)
  //   }
  //   return "Invalid Date."

  // }

  /**
   * Date
   */
  const {
    value: dobValue,
    isTouched: dobTouched,
    isValid: dobValueIsValid,
    isFocused: dobisFocused,
    hasError: dobHasError,
    inputChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
    reset: dobReset,
  } = useInput((value) => {
    return disable_validation ? value : value.trim() !== "" && (!value.includes('-') || ageValidation(new Date(value).valueOf())) && moment(value, 'YYYY-M-D', true).isValid()
  });


  /**
   * Physical address
  */

  // RegExp courtesy of Stackoverflow and Chat-GPT 
  var poBox = new RegExp(/^ *(?!pob)((pob[ #]\d+)|(#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i);

  const {
    value: pAddress,
    isTouched: pAddressTouched,
    isValid: pAddressIsValid,
    hasError: pAddressHasError,
    inputChangeHandler: pAddressChangeHandler,
    inputBlurHandler: pAddressBlurHandler,
    reset: pAddressReset,
  } = useInput((value) => 
    value.trim() !== ""
    &&
    !poBox.test(value)
    &&
    value.match(/^[a-zA-Z0-9 ]+$/gm)
    &&
    value.length <= 30
  );

  // const addressErrorMessageProvider = (input) => {
  //   const value = input.value;
  //   if(value.trim() === "") {
  //     return "Address is a required field"
  //   }else if(poBox.test(value)) {
  //     return "Address of residence cannot be a PO Box"
  //   }else if(!value.match(/^[a-zA-Z0-9 ]+$/gm)) {
  //     return "Only use alphanumeric characters."
  //   }else {
  //     return "Invalid Data"
  //   }
  // }

  const {
    value: pAddress_2,
    isTouched: pAddress_2Touched,
    isValid: pAddress_2IsValid,
    hasError: pAddress_2HasError,
    inputChangeHandler: pAddress_2ChangeHandler,
    inputBlurHandler: pAddress_2BlurHandler,
    reset: pAddress_2Reset,
  } = useInput((value) => value.trim() === "" || (value.length <= 30 && value.match(/^[a-zA-Z0-9 ]+$/gm)));

  const {
    value: pCity,
    isTouched: pCityTouched,
    isValid: pCityIsValid,
    hasError: pCityHasError,
    inputChangeHandler: pCityChangeHandler,
    inputBlurHandler: pCityBlurHandler,
    reset: pCityReset,
  } = useInput((value) => value.trim() !== "" && value.length >= 2 && value.length <= 50 && value.match(/^[a-zA-Z0-9 ]+$/gm));

  const {
    value: pZip,
    isTouched: pZipTouched,
    isValid: pZipIsValid,
    hasError: pZipHasError,
    inputChangeHandler: pZipChangeHandler,
    inputBlurHandler: pZipBlurHandler,
    reset: pZipReset,
  } = useInput((value) => disable_validation ? value : value.trim().length === 5 && value.match(/^[0-9]+$/gm));

  const {
    value: pZip_2,
    isTouched: pZip_2Touched,
    isValid: pZip_2IsValid,
    hasError: pZip_2HasError,
    inputChangeHandler: pZip_2ChangeHandler,
    inputBlurHandler: pZip_2BlurHandler,
    reset: pZip_2Reset,
  } = useInput(
    (value) => {
      // console.log(value)
      // console.log((value.trim().length === 0 || value.trim().length === 4))
      return value.trim().length === 0 || (value.trim().length === 4 && value.match(/^[0-9]+$/gm))
    },
    
  );

  const [physicalState, dispatchPhysicalState] = useReducer(
    physicalStateReducer,
    {
      value: "0",
      hasError: false,
    }
  );
  const { value: physicalStateValue } = physicalState;

  /**
   * Mailing address
   */
  const [isMailingAdress, setIsMailingAdress] = useState(false);

  const {
    value: mAddress,
    isTouched: mAddressTouched,
    isValid: mAddressIsValid,
    hasError: mAddressHasError,
    inputChangeHandler: mAddressChangeHandler,
    inputBlurHandler: mAddressBlurHandler,
    reset: mAddressReset,
  } = useInput((value) => (value.trim() === "" || value.match(/^[a-zA-Z0-9 ]+$/gm)) && value.length <= 30);

  const {
    value: mAddress_2,
    isTouched: mAddress_2Touched,
    isValid: mAddress_2IsValid,
    hasError: mAddress_2HasError,
    inputChangeHandler: mAddress_2ChangeHandler,
    inputBlurHandler: mAddress_2BlurHandler,
    reset: mAddress_2Reset,
  } = useInput((value) => value.trim() === "" || (value.length <= 30 && value.match(/^[a-zA-Z0-9 ]+$/gm)));

  const {
    value: mCity,
    isTouched: mCityTouched,
    isValid: mCityIsValid,
    hasError: mCityHasError,
    inputChangeHandler: mCityChangeHandler,
    inputBlurHandler: mCityBlurHandler,
    reset: mCityReset,
  } = useInput((value) => value.trim() !== "" && value.length >= 2 && value.length <= 50 && value.match(/^[a-zA-Z0-9 ]+$/gm));

  const {
    value: mZip,
    isTouched: mZipTouched,
    isValid: mZipIsValid,
    hasError: mZipHasError,
    inputChangeHandler: mZipChangeHandler,
    inputBlurHandler: mZipBlurHandler,
    reset: mZipReset,
  } = useInput((value) => value.trim().length === 5 && value.match(/^[0-9]+$/gm));

  const {
    value: mZip_2,
    isTouched: mZip_2Touched,
    isValid: mZip_2IsValid,
    hasError: mZip_2HasError,
    inputChangeHandler: mZip_2ChangeHandler,
    inputBlurHandler: mZip_2BlurHandler,
    reset: mZip_2Reset,
  } = useInput(
    (value) => value.trim().length === 0 || (value.trim().length === 4 && value.match(/^[0-9]+$/gm))
  );

  const [mailingState, dispatchMailingState] = useReducer(
    physicalStateReducer,
    {
      value: "0",
      hasError: false,
    }
  );
  const { value: mailingStateValue } = mailingState;

  /**
   * Phone
   */
  const initialPhone = {
    id: `${Math.random()}`,
    phone_number: "",
    phone_type: "0",
    phone_number_hasError: true,
    phone_type_hasError: true,
    phone_number_setValid: () => {},
    phone_type_setValid: () => {},
  };

  const errorCheckPhoneRows = useRef([false, false, false]);
  const [phones, setPhones] = useState([initialPhone]);

  /**
   * Email
   */
  const {
    value: email,
    isTouched: emailTouched,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput((value) => disable_validation ? value : (is.email(value) && value.length < 50));

  const {
    value: altEmail,
    isTouched: altEmailTouched,
    isValid: altEmailIsValid,
    hasError: altEmailHasError,
    inputChangeHandler: altEmailChangeHandler,
    inputBlurHandler: altEmailBlurHandler,
    reset: altEmailReset,
  } = useInput((value) => disable_validation ? value : (value.trim().length === 0 || (is.email(value) && value.length < 50)));

  /**
   * Form Valid
   */
  const [formIsValid, setformIsValid] = useState(false);
  const [formParams, setFormParams] = useState(null);
  const [newEnroll, setNewEnroll] = useState(null);

  /**
   * Modals
   */
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateIgnored, setDuplicateIgnored] = useState(false);
  const onCloseDupe = val => {
    setDuplicateIgnored(true);
    setShowDuplicateModal(val);
  }
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const successRef = React.createRef(null);
  
  useEffect(() => {
    getEnrollStatus().then(canEnroll => {
      console.log('checking status')
      if(!canEnroll) {
        navigate('/enroll_disabled');
      }
    })
  }, [navigate])


  /**
   * Duplicate Modal
   */
  useEffect(() => {
    if (
      firstNameIsValid &&
      !firstNameisFocused &&
      lastNameIsValid &&
      !lastNameisFocused &&
      dobValueIsValid &&
      !dobisFocused &&
      !duplicateIgnored) {
      setTimeout(() => {setDuplicateIgnored(true)}, 5000);
      getEnrollments({
        first: `${firstName}`,
        last: `${lastName}`,
        dob: `${dobValue}`,
      })
      .then((response) => {
        if (response && response.items) {
          console.log(response.items)
          for (const dupe of response.items) {
            // go to next duplicate if we dont meet criteria
            if (dupe.status !== "accepted") {
              continue;
            }
            if (new Date().year - new Date(dupe.submitted).year > 3 ) {
              continue
            }
            // if we're here then the dupe fit the conditions, so lets show the modal and get out
            setShowDuplicateModal(true)
            setDuplicateIgnored(true);
            break
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [
    firstNameIsValid,
    firstNameisFocused,
    lastNameIsValid,
    lastNameisFocused,
    dobValueIsValid,
    dobisFocused,
    firstName,
    lastName,
    dobValue,
    duplicateIgnored,
  ]);

  /**
   * Location
   */

  useEffect(() => {
    if (location.length > 1) {
      let defaultLocation = "0";
      let activeLocation = null;
      const optionsArr = location.map((item) => {
        if (localStorage.getItem("defaultLocation") === item.id) {
          defaultLocation = item.id;
          activeLocation = item;
        }
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        value: "0",
        label: "Select...",
        isDisabled: true,
      });

      dispatchLocation({ type: "SET_OPTIONS", payload: optionsArr });
      dispatchLocation({
        type: "SET_ACTIVE_LOCATION",
        payload: activeLocation,
      });
      dispatchLocation({ type: "CHANGE", payload: defaultLocation });
    } else {
      dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: location[0] });
    }
  }, [location]);

  const changeLocationHandler = (event) => {
    dispatchLocation({ type: "CHANGE", payload: event.target.value });

    const currentLocation = location.find(
      (item) => item.id === event.target.value
    );
    dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: currentLocation });
    cardNumberReset();
  };

  /**
   * Card Type
   */
  useEffect(() => {
    if (!activeLocation) return;

    let { cards } = activeLocation;
    setCurrentCards(cards);

    let optionsArr = [];


    if (!cards.length) {
      return;
    } else if (cards.length > 1) {
      optionsArr = cards.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        value: "0",
        label: "Select...",
        isDisabled: true,
      });

      dispatchCard({ type: "SET_ACTIVE_CARD", payload: null });
      dispatchCard({ type: "CHANGE", payload: "0" });
    } else {
      dispatchCard({ type: "SET_ACTIVE_CARD", payload: cards[0] });

      optionsArr.push({
        value: cards[0].id,
        label: cards[0].name,
        isDisabled: false,
      });

      dispatchCard({ type: "CHANGE", payload: cards[0].id });
    }

    dispatchCard({ type: "SET_OPTIONS", payload: optionsArr });
  }, [activeLocation]);

  const changeCardHandler = (event) => {
    dispatchCard({ type: "CHANGE", payload: event.target.value });

    const currentCard = currentCards.find(
      (item) => item.id === event.target.value
    );
    dispatchCard({ type: "SET_ACTIVE_CARD", payload: currentCard });
    cardNumberReset();
  };

  /**
   * State
   */
  const changePhysicalStateHandler = (event) => {
    dispatchPhysicalState({ type: "CHANGE", payload: event.target.value });
  };

  const changeMailingStateHandler = (event) => {
    dispatchMailingState({ type: "CHANGE", payload: event.target.value });
  };

  /**
   * Phone
   */
  const addPhoneNumberHandler = () => {
    setPhones((prevState) => {
      return [...prevState, initialPhone];
    });
  };

  /**
   * Submit
   */
  useEffect(() => {
    const mailingAddressIsValid = !isMailingAdress
      ? true
      : mAddressIsValid &&
        mCityIsValid &&
        mZipIsValid &&
        mZip_2IsValid &&
        mailingStateValue &&
        mailingStateValue !== "0";

    const phoneIsValid = getIsPhoneValid(phones);

    const isValid =
      activeLocation &&
      activeCard &&
      cardNumberIsValid &&
      firstNameIsValid &&
      lastNameIsValid &&
      identificationValueIsValid &&
      identificationValue_2IsValid &&
      dobValueIsValid &&
      pAddressIsValid &&
      pAddress_2IsValid &&
      mAddressIsValid &&
      mAddress_2IsValid &&
      pCityIsValid &&
      pZipIsValid &&
      pZip_2IsValid &&
      physicalStateValue &&
      physicalStateValue !== "0" &&
      mailingAddressIsValid &&
      emailIsValid &&
      altEmailIsValid &&
      phoneIsValid;

    if (isValid) {
      setformIsValid(true);
    } else {
      setformIsValid(false);
    }
  }, [
    activeLocation,
    activeCard,
    cardNumberIsValid,
    firstNameIsValid,
    lastNameIsValid,
    identificationValueIsValid,
    identificationValue_2IsValid,
    dobValueIsValid,
    pAddressIsValid,
    pAddress_2IsValid,
    pCityIsValid,
    pZipIsValid,
    pZip_2IsValid,
    physicalStateValue,
    isMailingAdress,
    mAddressIsValid,
    mAddress_2IsValid,
    mCityIsValid,
    mZipIsValid,
    mZip_2IsValid,
    mailingStateValue,
    emailIsValid,
    altEmailIsValid,
    phones,
    dobValue,
  ]);

  

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid && !disable_validation) {
      setFormParams(null);

      if (!activeLocation) {
        dispatchLocation({ type: "SET_VALID", payload: true });
      } else if (!activeCard) {
        dispatchCard({ type: "SET_VALID", payload: true });
      }

      if (!cardNumberIsValid) {
        cardNumberBlurHandler();
      }

      if (!firstNameIsValid) {
        firstNameBlurHandler();
      }

      if (!lastNameIsValid) {
        lastNameBlurHandler();
      }

      if (!identificationValueIsValid) {
        identificationValueBlurHandler();
      }

      if (!identificationValue_2IsValid) {
        identificationValue_2BlurHandler();
      }

      if (!dobValueIsValid) {
        dobBlurHandler();
      }

      if (!pAddressIsValid) {
        pAddressBlurHandler();
      }

      if (!pAddress_2IsValid) {
        pAddress_2BlurHandler();
      }

      if (!mAddressIsValid) {
        mAddressBlurHandler();
      }

      if (!mAddress_2IsValid) {
        mAddress_2BlurHandler();
      }

      if (!pCityIsValid) {
        pCityBlurHandler();
      }

      if (!pZipIsValid) {
        pZipBlurHandler();
      }

      if (!physicalStateValue || physicalStateValue === "0") {
        dispatchPhysicalState({ type: "SET_VALID", payload: true });
      }

      errorCheckPhoneRows.current.forEach(func => {
        if(func)
          func(true)
      });

      // Mailing address
      if (isMailingAdress) {
        if (!mAddressIsValid) {
          mAddressBlurHandler();
        }

        if (!mCityIsValid) {
          mCityBlurHandler();
        }

        if (!mZipIsValid) {
          mZipBlurHandler();
        }

        if (!mailingStateValue || mailingStateValue === "0") {
          dispatchMailingState({ type: "SET_VALID", payload: true });
        }
      }

      // email
      if (!emailIsValid) {
        emailBlurHandler();
      }

      const setPhonesValidation = () => {
        phones.forEach((item) => {
          if (item.phone_number_hasError) {
            item.phone_number_setValid();
          }

          if (item.phone_type_hasError) {
            item.phone_type_setValid();
          }
        });
      };

      setPhonesValidation();

      return;
    }

    const prepareBody = {
      location_id: `${locationState.value}`,
      first: `${firstName}`,
      last: `${lastName}`,
      dob: `${dobValue}`,
      identification_value: `${identificationValue}`,
      email: `${email}`,
      alternate_email: `${altEmail}`,
      physical_address: {
        address: `${pAddress}`,
        address_2: `${pAddress_2}`,
        city: `${pCity}`,
        state: `${physicalStateValue}`,
        zip: `${pZip}${"-" + pZip_2}`,
      },
      mailing_address: {
        address: isMailingAdress ? `${mAddress}` : `${pAddress}`,
        address_2: isMailingAdress ? `${mAddress_2}` : `${pAddress_2}`,
        city: isMailingAdress ? `${mCity}` : `${pCity}`,
        state: isMailingAdress
          ? `${mailingStateValue}`
          : `${physicalStateValue}`,
        zip: isMailingAdress
          ? `${mZip}${"-" + mZip_2}`
          : `${pZip}${"-" + pZip_2}`,
      },
      phones: phones.map((item) => ({
        phone_number: item.phone_number,
        phone_type: item.phone_type,
      })),
      card_id: `${cardState.value}`,
      card_number: `${enteredCardNumber}`,
    };

    if (!prepareBody.location_id) {
      // most likely only one location so field was skipped
      prepareBody.location_id = activeLocation.id
    }

    setFormParams(prepareBody);
    setShowReviewModal(true);
  };

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const createEnroll = () => {
    setShowWaitModal(true);

    createEnrollments(formParams)
      .then((response) => {
        console.log(response)
        if(response.action === 'redirect') {
          // enrollments are disabled actually /:
          navigate('/enroll_disabled')
          return;
        }
        if(response.req_successful === true) {
          console.log(response)
          setNewEnroll(response);
          setShowWaitModal(false);
          setShowSuccessModal(true);
          return;
        }


        setShowWaitModal(false);
        addNotification({
          status: "error",
          text: response.message,
          btn_text: "OK",
        });
        console.log(response)
      })
      .catch((error) => {
        console.error(error);
        addNotification({
          status: "error",
          text: "Sorry, there was an issue completing this enrollment.  Our technical team has been notified and will investigate this issue.",
          btn_text: "OK",
        });
        setShowWaitModal(false);
      });
  };

  const resetForm = () => {
    if (location.length > 1) {
      dispatchCard({ type: "SET_ACTIVE_CARD", payload: null });
      dispatchCard({ type: "SET_OPTIONS", payload: [] });

      dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: null });
      dispatchLocation({ type: "CHANGE", payload: "0" });
    } else {
      dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: location[0] });
    }

    cardNumberReset();

    firstNameReset();
    lastNameReset();
    identificationValueReset();
    identificationValue_2Reset();
    dobReset();

    pAddressReset();
    pAddress_2Reset();
    pCityReset();
    pZipReset();
    pZip_2Reset();
    dispatchPhysicalState({ type: "RESET" });

    setIsMailingAdress(false);
    mAddressReset();
    mAddress_2Reset();
    mCityReset();
    mZipReset();
    mZip_2Reset();
    dispatchMailingState({ type: "RESET" });

    setPhones([initialPhone]);

    emailReset();
    altEmailReset();

    setformIsValid(false);
    setFormParams(null);
    setNewEnroll(null);
    setDuplicateIgnored(false);

    window.scrollTo(0,0);
  };

  const physicalAddress = (
    <>
      <Space value="20" />

      <p className={classes.Text}>
        Applicant Resident/Physical Address
        ( <span className="link">Check mailing address option</span> for PO Boxes )
      </p>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            label="Address* (alphanumeric only, no special characters)"
            value={pAddress}
            touched={pAddressTouched}
            hasError={pAddressHasError}
            onChange={pAddressChangeHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('Address').isntEmpty().isAlphanumeric().isntPOBox().isUpTo(30).build()}
            onBlur={e => {
              pAddressBlurHandler(e);
              setDuplicateIgnored(true);
            }}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            placeholder="line 2 (optional)"
            label="Address Line 2"
            value={pAddress_2}
            touched={pAddress_2Touched}
            hasError={pAddress_2HasError}
            onChange={pAddress_2ChangeHandler}
            onBlur={pAddress_2BlurHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('Line 2').isAlphanumeric().isUpTo(30).build()}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            label="City* (alphanumeric only, no special characters)"
            value={pCity}
            touched={pCityTouched}
            hasError={pCityHasError}
            onChange={pCityChangeHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('City').isntEmpty().isAlphanumeric().isLength(2, 'characters').build()}
            onBlur={pCityBlurHandler}
          />
        </div>

        <div className={classes.Col__100}>
          <Select
            label="State*"
            options={stateOptions}
            value={physicalState.value}
            hasError={physicalState.hasError}
            onChange={changePhysicalStateHandler}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            type="text"
            label="Zip Code*"
            value={pZip}
            touched={pZipTouched}
            hasError={pZipHasError}
            onChange={e => {pZipChangeHandler(e);}}
            onBlur={pZipBlurHandler}
            maxLength={5}
            errorMessageProvider={new ErrorMessageProviderBuilder('Zip Code').isntEmpty().isNumeric().isLength(5, 'digits').build()}
          />
        </div>

        <div className={classes.Col__100}>
          <Input
            type="text"
            label="Zip Code Plus 4"
            value={pZip_2}
            touched={pZip_2Touched}
            hasError={pZip_2HasError}
            onChange={pZip_2ChangeHandler}
            onBlur={pZip_2BlurHandler}
            maxLength={4}
            errorMessageProvider={new ErrorMessageProviderBuilder('Plus 4').isNumeric().isLengths([0, 4], 'digits').build()}
          />
        </div>
      </div>
    </>
  );

  const mailingAddress = (
    <>
      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            label="Mailing Address (alphanumeric only, no special characters)"
            errorMessageProvider={new ErrorMessageProviderBuilder("Mailing Address").isAlphanumeric().isntPOBox().isUpTo(30).build()}
            value={mAddress}
            touched={mAddressTouched}
            hasError={mAddressHasError}
            onChange={mAddressChangeHandler}
            onBlur={mAddressBlurHandler}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            placeholder="line 2 (optional)"
            label="Mailing Address Line 2"
            value={mAddress_2}
            touched={mAddress_2Touched}
            hasError={mAddress_2HasError}
            onChange={mAddress_2ChangeHandler}
            onBlur={mAddress_2BlurHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('Line 2').isAlphanumeric().isUpTo(30).build()}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            label="City* (alphanumeric only, no special characters)"
            value={mCity}
            touched={mCityTouched}
            hasError={mCityHasError}
            onChange={mCityChangeHandler}
            onBlur={mCityBlurHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('City').isAlphanumeric().isLength(2, 'characters').build()}
          />
        </div>

        <div className={classes.Col__100}>
          <Select
            label="State*"
            options={stateOptions}
            value={mailingState.value}
            hasError={mailingState.hasError}
            onChange={changeMailingStateHandler}
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          <Input
            label="Zip Code*"
            value={mZip}
            touched={mZipTouched}
            hasError={mZipHasError}
            onChange={e => {mZipChangeHandler(e);}}
            onBlur={mZipBlurHandler}
            errorMessageProvider={new ErrorMessageProviderBuilder('Zip Code').isNumeric().isLength(5, 'digits').build()}
            maxLength={5}
          />
        </div>

        <div className={classes.Col__100}>
          <Input
            label="Zip Code Plus 4"
            value={mZip_2}
            touched={mZip_2Touched}
            hasError={mZip_2HasError}
            onChange={mZip_2ChangeHandler}
            onBlur={mZip_2BlurHandler}
            maxLength={4}
            errorMessageProvider={new ErrorMessageProviderBuilder('Plus 4').isNumeric().isLengths([0, 4], 'digits').build()}
          />
        </div>
      </div>
    </>
  );


  let phoneidx = 0;
  const phonesHtml = (
    <>
      <Space value="20" />

      <p className={classes.Text}>
        Please provide one or more phone numbers below:
      </p>

      {
        phones.map((item) => {
          return (
          <PhoneRow
            key={item.id}
            index={phoneidx++}
            phoneData={item}
            setPhones={setPhones}
            phones={phones}
            shouldDisplayError={errorCheckPhoneRows}
            disableValidation={disable_validation}
          />
        )})
      }

      <Space value="20" />
      {phones.length >= 3 ? (
        ""
      ) : (
        <span className={classes.Action} onClick={addPhoneNumberHandler}>
          + Additional Phone Number
        </span>
      )}
    </>
  );
  

  return (
    <>
      <div>
        <div className={classes.Heading}>
          <div className={classes.Img}>
            <img src={CreditCard} alt="card" />
          </div>
          <h2 className={classes.Title}>
            Please complete the Focus Card enrollment form below.
          </h2>
        </div>

        <Space value="25" />

        <form onSubmit={submitHandler}>
          {location.length > 1 && (
            <div className={classes.Row}>
              <div className={classes.Col__50}>
                <Select
                  label="Location:"
                  options={locationState.options}
                  value={locationState.value}
                  hasError={locationState.hasError}
                  onChange={changeLocationHandler}
                  errorMessage="Select Location"
                />
              </div>
            </div>
          )}

          <div className={classes.Row}>
            <div className={classes.Col__100}>
              <Select
                label="Card Type:"
                options={cardState.options}
                value={cardState.value}
                hasError={cardState.hasError}
                disabled={
                  !activeLocation || (currentCards && currentCards.length === 0)
                }
                onChange={changeCardHandler}
                errorMessage="Select Card Type"
              />
            </div>

            <div className={classes.Col__100}>
              {activeCard && activeCard.requires_card_number && (
                <Input
                  type="text"
                  label="Card Bank Account Number (last 10 digits)*"
                  value={enteredCardNumber}
                  touched={cardNumberTouched}
                  hasError={cardNumberHasError}
                  onChange={cardNumberChangeHandler}
                  onBlur={cardNumberBlurHandler}
                  errorMessageProvider={new ErrorMessageProviderBuilder('Card Number').isNumeric().isLength(10, 'digits').build()}
                />
              )}
            </div>
          </div>

          <div className={classes.Row}>
            <div className={classes.Text}>
              Applicant Name (alphanumeric only, no special characters)
            </div>
          </div>
 
          <div className={classes.Row}>
            <div className={classes.Col__100}>
              <Input
                label="First Name*"
                value={firstName}
                touched={firstNameTouched}
                hasError={firstNameHasError}
                onChange={firstNameChangeHandler}
                errorMessageProvider={new ErrorMessageProviderBuilder('This').isntEmpty().isAlphanumeric().build()}
                onBlur={firstNameBlurHandler}
              />
            </div>

            <div className={classes.Col__100}>
              <Input
                label="Last Name*"
                value={lastName}
                touched={lastNameTouched}
                hasError={lastNameHasError}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                errorMessageProvider={new ErrorMessageProviderBuilder('This').isntEmpty().isAlphanumeric().addCondition(
                  (value, field) => {
                    if((firstName + lastName).length > 24) {
                      return "Full name above 24 character limit"
                    }
                  }).build()}
                />
            </div>
          </div>

          <div className={classes.Row}>
            <div className={classes.Col__100}>
              <Input
                type="password"
                label="Social Security Number/ITIN*"
                placeholder="123456789"
                value={identificationValue}
                touched={identificationValueTouched}
                hasError={identificationValueHasError}
                onChange={identificationValueChangeHandler}
                onBlur={identificationValueBlurHandler}
              />
            </div>

            <div className={classes.Col__100}>
              <Input
                type="password"
                label="Social Security Number/ITIN (again)*"
                placeholder="123456789"
                value={identificationValue_2}
                touched={identificationValue_2Touched}
                hasError={identificationValue_2HasError}
                onChange={identificationValue_2ChangeHandler}
                onBlur={identificationValue_2BlurHandler}
                errorMessageProvider={repeatIDErrorMessageProvider}
              />
            </div>
          </div>

          <div className={classes.Row}>
            <div className={classes.Col__50}>
              <DateInput
                type="date"
                label="Date of Birth (Must be 14 or older)*"
                placeholder={{year: 'YYYY', month:'MM', day: 'DD'}}
                errorMessageProvider={new ErrorMessageProviderBuilder('Date of birth').isDate().isntEmpty().isYoungerThan(120).isOlderThan(14).formatWith(s => (<span><br/> {s}</span>)).build()}
                value={dobValue}
                touched={dobTouched}
                hasError={dobHasError}
                onChange={dobChangeHandler}
                onBlur={e => {
                  dobBlurHandler(e);
                }}
              />
            </div>
          </div>

          {physicalAddress}

          <div className={classes.Row}>
            <div className={classes.Col__100}>
              <Checkbox
                label="Mailing address is different from physical residence above."
                value={isMailingAdress}
                onChange={() => setIsMailingAdress(!isMailingAdress)}
              />
            </div>
          </div>

          {isMailingAdress && mailingAddress}

          {phonesHtml}

          <div className={classes.Row}>
            <div className={classes.Col__100}>
              <Input
                label="Primary Email Address*"
                placeholder="user@domain.com"
                value={email}
                touched={emailTouched}
                hasError={emailHasError}
                onChange={e => {emailChangeHandler(e); errorCheckPhoneRows.current.forEach(func => {if(func) func()})}}
                onBlur={e => {emailBlurHandler(e); errorCheckPhoneRows.current.forEach(func => {if(func) func()})}}
                errorMessageProvider={new ErrorMessageProviderBuilder('Your email').isntEmpty().isValidEmail().isUpTo(50).build()}
              />
            </div>

            <div className={classes.Col__100}>
              <Input
                label="Alternate Email"
                placeholder="user@domain.com"
                value={altEmail}
                touched={altEmailTouched}
                hasError={altEmailHasError}
                onChange={altEmailChangeHandler}
                onBlur={altEmailBlurHandler}
                errorMessageProvider={new ErrorMessageProviderBuilder('Your email').isntEmpty().isValidEmail().isUpTo(50).build()}
              />
            </div>
          </div>

          <Space value="20" />

          <Button color="primary" type="submit">
            Submit Enrollment
          </Button>
        </form>
      </div>

      {(showDuplicateModal && !showSuccessModal) &&
        ReactDOM.createPortal(
          <DuplicateModal
            onClose={onCloseDupe}
            first={firstName}
            last={lastName}
            dob={dobValue}
          />,
          document.querySelector("body")
        )}

      {showReviewModal &&
        ReactDOM.createPortal(
          <ReviewModal
            onClose={setShowReviewModal}
            onCreate={createEnroll}
            formParams={formParams}
            location={activeLocation.name}
            isMailingAdress={isMailingAdress}
          />,
          document.querySelector("body")
        )}

      {showWaitModal &&
        ReactDOM.createPortal(<WaitModal />, document.querySelector("body"))}

      {showSuccessModal &&
        ReactDOM.createPortal(
          <SuccessModal
            onClose={setShowSuccessModal}
            onReset={resetForm}
            params={newEnroll}
            fullName={`${firstName} ${lastName}`}
            ref={successRef}
          />,
          document.querySelector("body")
        )}

{notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={() => removeNotification()}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default EnrollForm;
