import { useEffect, useState } from "react";
import FileItem from "./FileItem";

import { getFiles } from "../../../api/api";

import classes from "./Files.module.scss";

const Files = ({ filesList, setFilesList }) => {
  const [list, setList] = useState(filesList.items);
  const [summary, setSummary] = useState(filesList.summary);
  const [loading, setLoading] = useState(false);
  
  const refreshHandler = () => {
    // clear list while fetching new data
    setList([]);
    setLoading(true);

    getFiles(1, 3)
      .then((response) => {
        setList(response.items);
        setSummary(response.summary);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
    });
  }
  const loadMoreHandler = () => {
    const pageNumber = +summary.current_page + 1;

    getFiles(pageNumber, 3)
      .then((response) => {
        setList((prevState) => {
          return [...prevState, ...response.items];
        });
        setSummary(response.summary);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    refreshHandler();
  }, [filesList]);

  return (
    <div className={classes.File__wrap}>
      {list.map((item) => (
        <FileItem key={item.id} item={item} setFilesList={setFilesList} />
      ))}
      
      {summary.more_items ? (
        <span
          className={`link ${classes.File__more}`}
          onClick={loadMoreHandler}
        >
          view older files &gt;
        </span>
      ) : null}
      <br />

      {loading ? (
        // If loading is true, display loading message
        <span>loading ...</span>
      ) : <br/>
      }
      
    </div>
  );
};

export default Files;
