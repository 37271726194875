import { useCallback, useEffect, useState } from "react";
import classes from "./Logo.module.scss";
import FocusLogo from "../../assets/images/focus.svg";
import CoordinatorLogo from "../../assets/images/focus_coordinator.svg"
import SuperCoordinatorLogo from "../../assets/images/focus_supercoordinator.svg"
import AdminLogo from "../../assets/images/focus_admin.svg"
import SecureUpload from "../../assets/images/secure_upload.svg"
import { getMe } from "../../api/api";

const Logo = () => {
  const [Image, setImage] = useState(FocusLogo)
  const [Text, setText] = useState("Focus Enrollment Portal")

  const ImageUpdate = useCallback(() => {
    console.log(`imageUpdate`)
    if (window.location.pathname === '/file-upload-utility') {
      setImage(SecureUpload)
      setText("SecureUpload")
      return
    }
    const logoType = localStorage.getItem("logo")
    switch (logoType) {
      case 'a': {
        setImage(AdminLogo)
        setText("Focus Enrollment Portal (admin)")
        break;
      }
      case 'sc': {
        setImage(SuperCoordinatorLogo)
        setText("Focus Enrollment Portal (super coordinator)")
        break;
      }
      case 'c': {
        setImage(CoordinatorLogo)
        setText("Focus Enrollment Portal (coordinator)")
        break;
      }
      default: {
        setImage(FocusLogo)
        setText("Focus Enrollment Portal")
        break;
      }
    }
  }, [])

  useEffect(() => {
    getMe().then((me) => {
      if(me.card_enrollment_user_level === 'Administrator') {
        window.localStorage.setItem("logo", "a")
      } else {
        if (me.card_enrollment_user_level === 'Super Coordinator') {
          localStorage.setItem("logo", "sc")
        } else if (me.card_enrollment_user_level === 'Coordinator') {
          localStorage.setItem("logo", "c")
        } else {
          alert('Your account does not have access to this site.  Please contact your CFR representative for additional support.')
          window.location = 'https://www.communityfinancialresources.org/'
        }
      }
      ImageUpdate()
    })
  }, [ ImageUpdate ])

  return (
      <div id="logo" className={classes.Logo}>
        <img src={Image} alt={Text} />
      </div>
    )
};

export default Logo;
