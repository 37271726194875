import Button from "../UI/Button/Button";
import classes from "./Heading.module.scss";
import { motion } from 'framer-motion';

const Heading = (props) => {
  return (
    <div className={classes.Heading}>
      <div className={`container ${classes.Heading__wrap}`}>
        <motion.h1
          initial={{opacity: 0, x: -60 }}
          animate={{opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className={classes.Heading__title}>
          {props.title}
        </motion.h1>
        <motion.p
          initial={{opacity: 0, x: -60 }}
          animate={{opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }} className={classes.Heading__description}>{props.description}</motion.p>

        {props.btn_text && (
          <div className={classes.Heading__btn}>
            <Button onClick={props.onClick}>{props.btn_text}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
