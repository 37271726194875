import { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import Card from "../components/Card/Card";
import Space from "../components/UI/Space/Space";
import Activity from "../components/Dashboard/Activity/Activity";
import HelpfulInformation from "../components/Dashboard/HelpfulInformation/HelpfulInformation";
import Enrollments from "../components/Dashboard/Enrollments/Enrollments";
import AboutLocation from "../components/Dashboard/AboutLocation/AboutLocation";
import OrgInfo from "../components/Dashboard/OrgInfo/OrgInfo";
import Charts from "../components/Dashboard/Charts/Charts"
import EnrollmentsByLocation from "../components/Dashboard/EnrollmentsByLocation/EnrollmentsByLocation.tsx";
import MessageBoard from "../components/Dashboard/MessageBoard/MessageBoard";
import {getMe} from "../api/api";

import { getActivity, getCbo, getEnrollments } from "../api/api";

import classes from "./pages.module.scss";

const DEFAULT_DESC = "It’s your location’s card enrollment at a glance."
const ADMIN_DESC = "Administrator, welcome back to the system!"

const Dashboard = () => {




  const [activityList, setActivityList] = useState(null);
  const [cbo, setCbo] = useState(null);
  const [enrollments, setEnrollments] = useState(null);
  const [description, setDescription] = useState(localStorage.getItem('isAdministrator') === 'true' ? ADMIN_DESC : DEFAULT_DESC);
  const [showOrgInfo, setShouldShowOrgInfo] = useState(localStorage.getItem('isAdministrator') === 'true');
  const [showCharts, setShouldShowCharts] = useState(localStorage.getItem('isAdministrator') === 'true');
  const [showEnrollmentsByLocation, setShouldShowEnrollmentsByLocation] = useState(localStorage.getItem('isAdministrator') === 'true');
  const [showMessageBoard, setShouldShowMessageBoard] = useState(localStorage.getItem('isAdministrator') === 'true');

  const setInterfaceVars = (isAdmin) => {
    setDescription(isAdmin ? ADMIN_DESC : DEFAULT_DESC); //sets Special Dashboard Title for admin
    setShouldShowOrgInfo(isAdmin); //shows org info for admin
    setShouldShowCharts(isAdmin); //shows charts for admin
    setShouldShowEnrollmentsByLocation(isAdmin); //shows Enropllments by location for admin
    setShouldShowMessageBoard(isAdmin); //shows message board for admin
    console.log(isAdmin)
    localStorage.setItem('isAdministrator', isAdmin);
  }


  const fetchData = () => {
    Promise.allSettled([
      getActivity(1, 3),
      getCbo(),
      getEnrollments(),
    ])
      .then((allData) => {
        if (allData[0].status === "fulfilled") {
          setActivityList(allData[0].value);
        }

        if (allData[1].status === "fulfilled") {
          setCbo(allData[1].value);
        }

        if (allData[2].status === "fulfilled") {
          setEnrollments(allData[2].value);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    getMe().then((me) => {
      if (['Administrator'].includes(me.card_enrollment_user_level)) {
        setInterfaceVars(true);
        return;
      }else {
        setInterfaceVars(false);
        return;
      }
    });
  }, []);

  return (
    <Layout>
      <Heading
        title="Dashboard"
        description={description}
      />

      <Space value="50" />

      { showOrgInfo &&
        <OrgInfo />
      }

      { showCharts &&
        <Charts />
      }

      { showEnrollmentsByLocation &&
        <EnrollmentsByLocation />
      }

      { showMessageBoard &&
          <MessageBoard />
      }

      { !showOrgInfo && !showCharts && !showEnrollmentsByLocation && !showMessageBoard &&

        <div className="container">
          <div className={classes.Row}>
            <div className={classes.Dashboard__left}>
              <Card>
                <h2 className={classes.Title}>
                  Your location’s recent activity
                </h2>

                {activityList ? <Activity activityList={activityList} /> : null}
              </Card>
            </div>

            <div className={classes.Dashboard__right}>
              <Card>
                {/* <h2 className={classes.Title}>Helpful information</h2> */}

                {cbo ? <HelpfulInformation cbo={cbo} /> : null }
              </Card>
            </div>
          </div>

          <div className={classes.Row}>
            <div className={classes.Dashboard__left}>
              <Card>
                <h2 className={classes.Title}>Accepted Enrollments</h2>

                {enrollments ? <Enrollments enrollments={enrollments} /> : null }
              </Card>
            </div>

            <div className={classes.Dashboard__right}>
              <Card>
                <h2 className={classes.Title}>About your location</h2>

                <AboutLocation cbo={cbo} />
              </Card>
            </div>
          </div>
        </div>

      }

    </Layout>
  );
};

export default Dashboard;
