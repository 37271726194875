import FilterListIcon from '@mui/icons-material/FilterList';
import classes from "../../List.module.scss";
import { useState, useEffect, useRef } from 'react';

const DynamicFilterDropdown = ({ filterState, setFilterState }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        window.addEventListener('click', handleClickOutside)

        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleClickFilter = (key) => {
        console.log("Clicked " + key + " filter")
        var updatedFilterState = filterState
        updatedFilterState[key].active = true
        setFilterState(updatedFilterState)
        console.log(filterState)
    }

    return (
        <span ref={dropdownRef}>
            <span className={classes.Form__filter} onClick={function () {setIsOpen(!isOpen)}}>
                <span>
                    ADD FILTER
                </span>
                <FilterListIcon className={classes.Form__filter__icon}/>
                {isOpen && (
                    <div className={classes.Form__filter__dropdown__menu}>
                        {Object.keys(filterState).map((key) => !(filterState[key].active) ? (
                            <div
                                key={key}
                                value={"test"}
                                className={classes.Form__filter__dropdown__item}
                                onClick={() => handleClickFilter(key)}
                            >
                                {filterState[key].label}
                            </div>
                        ) : null )}
                    </div>
                )}
            </span>
        </span>
    )
}

export default DynamicFilterDropdown;
