import { useReducer, useEffect } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";

import useInput from "../../../hooks/useInput";
import { getEnrollmentsExport } from "../../../api/api";
import { downloadFile, csvMaker } from "../../../assets/helper";

import Download from "../../../assets/svg/Download";
import classes from "../List.module.scss";
import DynamicFilterDropdown from "../Common/DynamicFilters/FilterDropdown";
import DynamicFilterInputs from "../Common/DynamicFilters/FilterInputs";

const selectInitialState = () => {
  return { options: [], value: ""}
}

const selectReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      value: state.value,
    }
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      value: action.payload,
    }
  }

  if (action.type === "RESET") {
    return {
      options: state.options,
      value: "",
    }
  }

  return  selectInitialState();
}

const SearchForm = ({ searchParams, onSearch, location, cbo, filterState, setFilterState, filterValues }) => {
  const firstParam = searchParams ? searchParams.first : null;
  const lastParam = searchParams ? searchParams.last : null;
  const resets = [];

  /**
   * Name
   */
  const {
    value: firstName,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(() => true, firstParam);
  resets.push(resetFirstName)

  const {
    value: lastName,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(() => true, lastParam);
  resets.push(resetLastName)

  /**
   * ID
   */
  const {
    value: idValue,
    inputChangeHandler: idChangeHandler,
    inputBlurHandler: idBlurHandler,
    reset: resetId,
  } = useInput(() => true);
  resets.push(resetId)

  /**
   * CBO
   */
  const [cboState, dispatchCbo] = useReducer(
    selectReducer,
    selectInitialState()
  )
  resets.push(() => {dispatchCbo({ type: "RESET" })})

  /**
   * Location
   */
  const [locationState, dispatchLocation] = useReducer(
    selectReducer,
    selectInitialState()
  );
  resets.push(() => {dispatchLocation({ type: "RESET" })})

  useEffect(() => {
    if (location.length > 1) {
      const optionsArr = location.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        label: "Select a location...",
        isDisabled: false,
        value: "",
      });

      dispatchLocation({ type: "SET_OPTIONS", payload: optionsArr });
    }
  }, [location]);

  useEffect(() => {
    if (cbo.length > 1) {
      const cboOptionsArr = cbo.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        }
      })

      cboOptionsArr.unshift({
        label: "Select a CBO...",
        isDisable: false,
        value: "",
      })

      dispatchCbo({ type: "SET_OPTIONS", payload: cboOptionsArr })
    }
  }, [cbo])

  const changeLocationHandler = (event) => {
    dispatchLocation({ type: "CHANGE", payload: event.target.value });
  };

  const changeCboHandler = (event) => {
    dispatchCbo({ type: "CHANGE", payload: event.target.value })
  }

  const submitHandler = (event) => {
    //console.log('dob: ' + dobValue)
    event.preventDefault();
    event.target.reset();

    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      id: `${idValue}` || null,
    };

    if (locationState.value) {
      prepareBody.location_id = locationState.value;
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value;
    }

    const keys = Object.keys(filterState)
    for (const key of keys) {
      if (filterState[key].active) {
        prepareBody[key] = filterValues[key].value
      }
    }

    onSearch(prepareBody);
  };

  const downloadHandler = () => {
    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      id: `${idValue}` || null,
      cbo_id: `${cboState.value}` || null,
      location_id: `${locationState.value}` || null,
    };

    if (locationState.value) {
      prepareBody.location_id = locationState.value;
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value;
    }

    const keys = Object.keys(filterState)
    for (const key of keys) {
      if (filterState[key].active) {
        prepareBody[key] = filterValues[key].value
      }
    }

    getEnrollmentsExport(prepareBody)
      .then((response) => {
        const csvData = csvMaker(response);
        downloadFile(csvData, "enrollments");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Nothing else worked, and i was running out of time, so i just made a function to clear all the inputs
  const runResets = (e) => {
    resets.forEach(function (f) { f() })
  }

  return (
    <div>
      <div className={classes.Form__container}>
        <div className="container">
          <div className={classes.Form__text}>
            Enter <b>any</b> search parameter below and click search.   
            <span onClick={runResets} className={classes.Form__clear}>
                RESET FILTERS
            </span>
            <DynamicFilterDropdown
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </div>

          <form onSubmit={submitHandler} className={classes.Form}>

            
              <div className={classes.Form__col}>

                <div className={classes.Form__input__long}>
                  <Input
                    label="First Name:"
                    clsType="small"
                    value={firstName}
                    onChange={firstNameChangeHandler}
                    onBlur={firstNameBlurHandler}
                    placeholder="Jane"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Form__input__long}>
                  <Input
                    label="Last Name:"
                    clsType="small"
                    value={lastName}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                    placeholder="Kenedy"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Form__input__small}>
                  <Input
                    type="number"
                    clsType="small"
                    label="Confirmation:"
                    value={idValue}
                    onChange={idChangeHandler}
                    onBlur={idBlurHandler}
                    placeholder="10000000"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Select}>
                  <Select
                    clsType="small"
                    label="CBO:"
                    value={cboState.value}
                    options={cboState.options}
                    onChange={changeCboHandler}
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Select}>
                  <Select
                    clsType="small"
                    label="Location:"
                    value={locationState.value}
                    options={locationState.options}
                    onChange={changeLocationHandler}
                  />
                </div>

              </div>

              {/* <DynamicFilterInputs
                filterState={filterState}
                setFilterState={setFilterState}
                resets={resets}
                filterValues={filterValues}
              /> */}

              <div className={classes.Form__col}>

                <Button color="primary" type="submit" clsType="small">
                  Search
                </Button>

              </div>
          </form>

          <DynamicFilterInputs
            filterState={filterState}
            setFilterState={setFilterState}
            resets={resets}
            filterValues={filterValues}
          />

        </div>
        {/* <DynamicFilterInputs
          filterState={filterState}
          setFilterState={setFilterState}
          resets={resets}
          filterValues={filterValues}
        /> */}
      </div>

      <div className="container">
        <div className={classes.Row}>
           <div className={classes.DownloadCol}>
          {true && (
            <div className={classes.Btn} onClick={downloadHandler}>
              Download <Download />
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
