import { useReducer } from "react";

const initialInputState = (value) => {
  return {
    value: value || "",
    isTouched: value ? true : false,
    isFocused: false
  };
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return {
      value: action.value,
      isTouched: state.isTouched,
      isFocused: true
    };
  }
  if (action.type === "BLUR") {
    return {
      isTouched: true,
      value: state.value,
      isFocused: false
    };
  }

  if (action.type === "RESET") {
    return {
      value: "",
      isTouched: false,
      isFocused: false
    };
  }

  return initialInputState;
};

const useInput = (validateValue, value, type) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState(value)
  );
  
  let hasError;
  let valueIsValid;

  valueIsValid = validateValue(inputState.value);
  hasError = !valueIsValid && inputState.isTouched;

  const inputChangeHandler = (event) => {
    if (type === "number") {
      const re = /^[0-9\b]+$/;

      if (event.target.value === "" || re.test(event.target.value)) {
        dispatch({ type: "INPUT", value: event.target.value });
      }
    } else {
      dispatch({ type: "INPUT", value: event.target.value });
    }
  };

  const inputBlurHandler = () => {
    dispatch({ type: "BLUR" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };


  return {
    value: inputState.value,
    isTouched: inputState.isTouched,
    isValid: valueIsValid,
    isFocused: inputState.isFocused,
    hasError,
    inputChangeHandler,
    inputBlurHandler,
    reset
  };
};

export default useInput;
