import is from "is_js";
import moment from "moment";

type MessageProvider = (value: string, fieldName: string) => string | false;

export class ErrorMessageProviderBuilder {
    static poBox = new RegExp(/^ *(?!pob)((pob[ #]\d+)|(#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i);
    name: string;
    conditions: MessageProvider[] = [];
    formatter: ((result: string) => string) | null = null;
    static previousBuilders: ErrorMessageProviderBuilder[] = [];

    constructor(fieldName: string) {
        if(fieldName === undefined) {
            throw new Error('Field name is required');
        }
        this.name = fieldName;
    }

    isDate(): DateErrorMessageProviderBuilder {
        return new DateErrorMessageProviderBuilder(this);
    }

    protected getValue(input: any): string | false {
        if(input === null || input === undefined) {
            return false;
        }

        if(typeof(input) == typeof("")) {
            return input;
        }

        return input.value;
    }

    protected isValid(input: any): boolean {
        return input !== undefined;
    }

    isValidEmail() {
        return this.addCondition((value, fieldName) => {
            if(is.email(value)) {
                return false;
            }
            return `${fieldName} must be a valid email`;
        });
    }
    
    addCondition(condition: MessageProvider) {
        this.conditions.push(condition);
        return this;
    }

    isntEmpty() {
        return this.addCondition((value, fieldName) => {
            if(!value || value.trim() === '') {
                return `${fieldName} is a required field`;
            }
            return false;
        });
    }

    isAlphanumeric() {
        return this.addCondition((value, fieldName) => {
            if(!value.match(/^[a-zA-Z0-9 ]+$/gm)) {
                return `${fieldName} must be alphanumeric`;
            }
            return false;
        });
    }

    isNumeric() {
        return this.addCondition((value, fieldName) => {
            if(!value.match(/^[0-9]+$/gm)) {
                return `${fieldName} must be numeric`;
            }
            return false;
        });
    }

    isLengths(lengths: number[], characterName: string) {
        return this.addCondition((value, fieldName) => {
            if(lengths.indexOf(value.length) === -1) {
                //Add or when neccisary
                return `${fieldName} must be ${lengths.length > 1 ? 'either' : ''} ${lengths.join(' or ').replace('0', 'empty')} ${characterName} long`;
            }
            return false;
        });
    }
    
    isLength(length: number, characterName: string) {
        return this.addCondition((value, fieldName) => {
            if(value.length !== length) {
                return `${fieldName} must be ${length} ${characterName} long`;
            }
            return false;
        });
    }

    isntPOBox() {
        return this.addCondition((value, fieldName) => {
            if(ErrorMessageProviderBuilder.poBox.test(value)) {
                return `${fieldName} cannot be a PO box. Use the Mailing Address Field instead`;
            }
            return false;
        });
    }

    isUpTo(charCount: number) {
        return this.addCondition((value, fieldName) => {
            if(value.length > charCount) {
                return `${fieldName} must be below ${charCount} characters long.`
            }
            return false;
        })
    }

    formatWith(formatter: (result: string) => string) {
        this.formatter = formatter;
        return this;
    }

    build() {
        ErrorMessageProviderBuilder.previousBuilders.push(this);
        const built = (input) => {
            const value = this.getValue(input);

            if(value === false) {
                return '';
            }
            for(const condition of this.conditions) {
                const res = condition(value, this.name)
                if(res !== false) {
                    // console.log(`${this.name} failed at condition ${i}`)
                    if(this.formatter !== null) {
                        
                        return this.formatter(res);
                    }
                    return res;
                }
            }
            return 'Invalid Data';
        }

        return built;
    }

    passes(input: any): boolean {
        if(!input) {
            return false;
        }
        if(!this.isValid(input)) {
            return false;
        }

        const value = this.getValue(input);
        if(value === false) {
            return false;
        }
        for(const condition of this.conditions) {
            const res = condition(value, this.name)
            if(res !== false) {
                return false;
            }
        }
        return true;
    }

    protected copyFrom(other: ErrorMessageProviderBuilder) {
        this.conditions = other.conditions;
        this.formatter = other.formatter;
    }
}

export class DateErrorMessageProviderBuilder extends ErrorMessageProviderBuilder {
    static yearInEpoch = 1000 * 60 * 60 * 24 * 365;

    constructor(old: ErrorMessageProviderBuilder) {
        super(old.name)
        this.copyFrom(old);

        this.addCondition((value, fieldName) => {
            if(moment(value, 'MM/DD/YYYY', false).isValid()) {
                return false;
            }
            return `${fieldName} must be a valid date`;
        })
    }

    protected override getValue(input: any): string | false {
        return input.map(date => date.current).join('/');
    }

    protected override isValid(input: any): boolean {
        return Array.isArray(input) && input.length === 3;
    }

    protected getAge(date: string) {
        const age = moment(new Date()).diff(moment(date, "MM/DD/YYYY", false));
        const yearAge = age / DateErrorMessageProviderBuilder.yearInEpoch;
        return yearAge;
    }

    isOlderThan(minAge: number) {
        return this.addCondition((value, fieldName) => {    
            const age = this.getAge(value);

            if (age < minAge) {
                return `${fieldName} must be at least ${minAge}. Was ${age > 0 ? Math.floor(age) : '0'}`;
            }
            return false;
        });
    }

    isYoungerThan(maxAge: number) {
        return this.addCondition((value, fieldName) => {    
            const age = this.getAge(value);

            if (age > maxAge) {                
                return `${fieldName} must be less than ${maxAge} years ago`;
            }
            return false;
        });
    }
}