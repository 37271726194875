import { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";

import Button from "../../UI/Button/Button";
import Notification from "../../UI/Notification/Notification";
import useNotification from "../../../hooks/useNotification";

import { uploadFile } from "../../../api/api";

import classes from "./DropZone.module.scss";
import WaitModal from "../../Enroll/WaitModal";

const DropZone = ({ onRender }) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "all": ["*"]
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const uploadHandler = () => {
    addNotification({
      status: "success",
      text: "Uploading your file.  Please wait...",
      btn_text: "CANCEL",
    });
    setUploading(true);

    const fileName = files.length ? `${files[0].path}` : "No file";
    if(fileName.length > 50) {
      setUploading(false)
      addNotification({
        status: "error",
        text: `File name exceeds 50 character maximum.`,
        btn_text: "OK",
      });

      setTimeout(() => {
        removeNotification();
      }, 5000);
      return;
    }
    const finishedCallback = (r) => {
      if(r) {
        addNotification({
          status: "success",
          text: `${fileName} has been successfully uploaded.`,
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
          setUploading(false);
        }, 3000);
      }else {
        setUploading(false);
      }
    };
    uploadFile(files, fileName, finishedCallback)
      .then(() => {
        // setUploading(false);
        removeNotification();
        onRender();
        setFiles([]);
        
        setTimeout(() => {
          removeNotification();
        }, 3000);
      })
      .catch((error) => {
        removeNotification();
        console.error(error);

        setUploading(false);

        addNotification({
          status: "error",
          text: error.message || "Problem uploading file.  Please check the file and your network connection and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  return (
    <>
      <div className={classes.Wrapper}>
        <div
          className={`${classes.DropZone} ${
            isDragActive ? classes.active : ""
          }`}
          {...getRootProps()}
        >
          <span className={classes.DropZone__text}>Drag Files Here</span>

          <input {...getInputProps()} />
        </div>

        <div className={classes.Row}>
          <div className={classes.DropZone__input} onClick={open}>
            <button className={classes.DropZone__input__btn}>
              Choose File
            </button>
            <div className={classes.DropZone__input__text}>
              {files.length ? `${files[0].path}` : "No file chosen"}
            </div>
          </div>
          <div className={classes.DropZone__btn}>
            <Button onClick={uploadHandler}>Upload</Button>
          </div>
        </div>
      </div>
      {isUploading &&
        ReactDOM.createPortal(<WaitModal
          message="Securely Uploading your file"
        />, document.querySelector("body"))
      }

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={removeNotification}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default DropZone;
