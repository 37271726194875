import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Enroll from "./pages/Enroll";
import Search from "./pages/Search";
import EnrollmentList from "./pages/EnrollmentList";
import Users from "./pages/Users";
import EnrollmentDetail from "./pages/EnrollmentDetail";
import FileUpload from "./pages/FileUpload";
import Help from "./pages/Help";
import NotExist from "./pages/NotExist.tsx";
import EnrollDisabled from "./pages/EnrollDisabled.tsx";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import QueryTool from "./pages/QueryTool";

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/enroll" element={<Enroll />} />
          <Route path='/enroll_disabled' element={<EnrollDisabled/>} />
          <Route path="/search" element={<Search />} />
          <Route path="/enrollments" element={<EnrollmentList />} />
          <Route path="/users" element={<Users />} />
          <Route path="/enrollment-detail/:id" element={<EnrollmentDetail />} />
          <Route path="/file-upload-utility" element={<FileUpload />} />
          <Route path="/help" element={<Help />} />
          <Route path='/notexist' element={<NotExist/>} />
          <Route path='/query-tool' element={<QueryTool/>} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
