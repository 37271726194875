import useInput from "../../hooks/useInput";
import is from "is_js";
import Modal from "../UI/Modal/Modal";
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import { useEffect, useRef, useState } from "react";
import { getCBOs, getLocationsFromCBO } from "../../api/api";
import Checkbox from "../UI/Checkbox/Checkbox";
import classes from "./Users.module.scss";
import Button from "../UI/Button/Button";

const roles = [
    {
        label: 'Coordinator',
        value: '1'
    },
    {
        label: 'Super Coordinator',
        value: '2'
    },
    {
        label: 'Administrator',
        value: '-1'
    }
]

const contacts = [
    {
        label: 'Do not replace',
        value: 'dnr'
    },
    {
        label: 'Primary Contact',
        value: 'pc'
    },
    {
        label: 'Secondary Contact',
        value: 'sc'
    },
]

const AdminInviteUserModal = ({ onClose, onSave }) => {
    const [locations, setLocations] = useState(null);
    const [CBOs, setCBOs] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    let defaultChoiceLocation = useRef(null);
    let defualtRole = useRef('1');

    /**
     * Role
     */
    const {
        value: role,
        hasError: roleHasError,
        inputChangeHandler: roleChangeHandler,
    } = useInput(value => roles.map(role => role.name).includes(value))

    /**
     * CBO
     */
    const {
        value: cbo,
        hasError: cboHasError,
        inputChangeHandler: cboChangeHandler
    } = useInput(value => {
        if (CBOs === null) {
            return true;
        }
        return CBOs.map(c => c.label).includes(value) && value !== ''
    })

    useEffect(() => {
        if (CBOs !== null) {
            return;
        }
        getCBOs().then((cbos) => {
            const mapped = cbos.map(cbo => {
                return {
                    label: cbo.name,
                    value: cbo.id
                }
            })
            mapped.splice(0, 0, {
                label: 'Select a CBO',
                value: ''
            })
            setCBOs(mapped);
            updateLocations();
        })
    })

    /**
     * Location
     */
    let {
        value: location,
        hasError: locationHasError,
        inputChangeHandler: locationChangeHandler
    } = useInput(value => {
        if (locations === null) {
            return true;
        }
        return locations.map(l => l.label).includes(value)
    })

    function updateLocations() {
        setLocations(null)
        getLocationsFromCBO(cbo).then((locs) => {
            if (locs === null) {
                return;
            }
            const mapped = locs.map(loc => {
                return {
                    label: loc.name,
                    value: loc.id,
                }
            })
            defaultChoiceLocation.current = mapped[0].value;
            setLocations(mapped)
        })
    }

    useEffect(updateLocations, [cbo])

    /**
     * Name
     */
    const {
        value: name,
        isTouched: nameTouched,
        hasError: nameHasError,
        inputChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
    } = useInput(value => value !== '')

    /**
     * Email
     */
    const {
        value: email,
        isTouched: emailTouched,
        isValid: emailIsValid,
        hasError: emailHasError,
        inputChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
    } = useInput(value => is.email(value))

    /**
     * Location Contact
     */
    const {
        value: contact,
        hasError: contactHasError,
        inputChangeHandler: contactChangeHandler,
    } = useInput(value => true)

    const [allowFileUpload, setAllowFileUpload] = useState(false);
    const fileUploadChangeHandler = (v) => {
        setAllowFileUpload(!allowFileUpload)
    }


    const saveHandler = (event) => {
        event.preventDefault();

        if (!emailIsValid) {
            emailBlurHandler();
            return;
        }



        const prepareBody = {
            name: `${name}`,
            email: `${email}`,
            location_id: `${location || defaultChoiceLocation.current}`,
            location_contact: `${contact}`,
            allow_file_upload: `${allowFileUpload}`,
            role: parseInt(role || defualtRole.current)
        };

        if(role === '2') {
            delete prepareBody.location_id;
            prepareBody.cbo_id = cbo;
        }

        setIsProcessing(true)
        onSave(prepareBody)
        setTimeout(() => setIsProcessing(false), 4000)
    }

    return (
        <Modal title="Invite New User (Admin)" onClose={onClose}>
            <form onSubmit={saveHandler} className={classes.AdminModal}>
                <Select
                    label="Role:*"
                    options={roles}
                    alt={role}
                    clsType="small"
                    value={role}
                    onChange={roleChangeHandler}
                    hasError={roleHasError}
                    error="Choose a role."
                />
                <Select
                    label="CBO:*"
                    options={CBOs}
                    alt={cbo}
                    clsType="small"
                    value={cbo}
                    onChange={cboChangeHandler}
                    hasError={cboHasError}
                    error="Choose a CBO."
                />
                <div style={{display: role === '2' ? 'none' : 'block'}}>
                <Select
                    label="Location:*"
                    options={locations}
                    clsType="small"
                    disabled={locations === null}
                    value={location}
                    hasError={locationHasError}
                    onChange={locationChangeHandler}
                    errorMessage="Select Location"
                />
                </div>
                <Input
                    label="Name:*"
                    clsType="small"
                    required={true}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    value={name}
                    touched={nameTouched}
                    hasError={nameHasError}
                />

                <Input
                    label="Email:*"
                    clsType="small"
                    required={true}
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={email}
                    touched={emailTouched}
                    hasError={emailHasError}
                    isValid={emailIsValid}
                />

                <Select
                    label="Location Contact:*"
                    options={contacts}
                    alt={contact}
                    clsType="small"
                    value={contact}
                    onChange={contactChangeHandler}
                    hasError={contactHasError}
                    error="Choose a role."
                />

                <Checkbox
                    label="Should be allowed to upload files"
                    value={allowFileUpload}
                    onChange={fileUploadChangeHandler}
                />
                <div className={classes.Modal__center}>
                    <Button color="primary" type="submit" disabled={isProcessing}>
                        Update
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default AdminInviteUserModal;