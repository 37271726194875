import Header from "../Header/Header";
import classes from "./Layout.module.scss";
import Footer from "../Footer/Footer";



function Layout(props) {
  return (
    <>
      <Header />

      <main id="main">
        <div className={classes.Layout}>
          <div>{props.children}</div>
        </div>
      </main>
      <Footer />
    </>
  );
}
export default Layout;
