import classes from "./OrgInfo.module.scss";
import IconUsers from "../../../assets/images/icon-users.svg";
import IconEnrollment from "../../../assets/images/icon-enrollment.svg";
import IconLocation from "../../../assets/images/icon-location.svg";
import IconOrganization from "../../../assets/images/icon-organization.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOrgInfo } from "../../../api/api";
import {motion} from "framer-motion"
const LS_KEY = "admin_org_info";

const OrgInfo = () => {
    const [info, setInfo] = useState({
        users: '__',
        enrollments: '____',
        locations: '___',
        cbos: '___'
    })

    const updateOrgInfo = (orgInfo) => {
        window.localStorage.setItem(LS_KEY, JSON.stringify(orgInfo))

        setInfo(orgInfo);
    }

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const setOrgInfoFromLocalStorage = () => {
        const ls = window.localStorage.getItem(LS_KEY);

        if(ls) {
            setInfo(JSON.parse(ls));
        }
    }

    useEffect(() => {
        setOrgInfoFromLocalStorage();
        getOrgInfo().then(updateOrgInfo)
    }, [])


    return (
        <div className={classes.OrgInfo}>
            <div className="container">
                <div className="row">
                    <motion.div initial={{opacity: 0, y: -20 }} animate={{opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={`col-sm-6 col-lg-3 ` + classes.OrgInfo__Col}>
                        <Link className={classes.OrgInfo__Col_Inner} to={`/users`}>
                            <div className={classes.OrgInfo__Col_Inner_Left}>
                                <img src={IconUsers} alt="User Icon"/>
                            </div>
                            <div className={classes.OrgInfo__Col_Inner_Right}>
                                <h2>Users</h2>
                                <p>{numberWithCommas(info.users || 0)}</p>
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div initial={{opacity: 0, y: -20 }} animate={{opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={`col-sm-6 col-lg-3 ` + classes.OrgInfo__Col}>
                        <Link className={classes.OrgInfo__Col_Inner} to={`/enrollments`}>
                            <div className={classes.OrgInfo__Col_Inner_Left}>
                                <img src={IconEnrollment} alt="Enrollments Icon"/>
                            </div>
                            <div className={classes.OrgInfo__Col_Inner_Right}>
                                <h2>Enrollments</h2>
                                <p>{numberWithCommas(info.enrollments || 0)}</p>
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div initial={{opacity: 0, y: -20 }} animate={{opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={`col-sm-6 col-lg-3 ` + classes.OrgInfo__Col}>
                        <Link className={classes.OrgInfo__Col_Inner} to={`/locations`}>
                            <div className={classes.OrgInfo__Col_Inner_Left}>
                                <img src={IconLocation} alt="Locations Icon"/>
                            </div>
                            <div className={classes.OrgInfo__Col_Inner_Right}>
                                <h2>Locations</h2>
                                <p>{numberWithCommas(info.locations || 0)}</p>
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div initial={{opacity: 0, y: -20 }} animate={{opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={`col-sm-6 col-lg-3 ` + classes.OrgInfo__Col}>
                        <Link className={classes.OrgInfo__Col_Inner} to={`/organizations`}>
                            <div className={classes.OrgInfo__Col_Inner_Left}>
                                <img src={IconOrganization} alt="Organizations Icon"/>
                            </div>
                            <div className={classes.OrgInfo__Col_Inner_Right}>
                                <h2>Organizations</h2>
                                <p>{numberWithCommas(info.cbos || 0)}</p>
                            </div>
                        </Link>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default OrgInfo;