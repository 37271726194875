import React from 'react'
import classes from "./ToggleSwitch.module.scss"

function ToggleSwitch({ value, left_text, right_text, onClick }) {

    return (
        <>
            <div className={classes.toggle} onClick={onClick} >
                <div className={classes.inner_toggle + " " + (value ? classes.on : classes.off)}>
                    {left_text}
                </div>
                <div className={classes.inner_toggle + " " + (value ? classes.off : classes.on)}>
                    {right_text}
                </div>
            </div>
        </>
    )
}


export default ToggleSwitch;