import React, { useEffect, useState } from 'react';
import Select from '../UI/Select/Select';
import Space from '../UI/Space/Space';
import { getSavedQueries } from '../../api/api';

type Query = {name: string, sql_query: string, db: 'warehouse' | 'enroll'};

const SavedQueries = ({ onQueryChanged, value, setValue, refreshQueries }: { value, setValue, onQueryChanged: (q: Query) => void, refreshQueries: any}) => {
    const [queries, setQueries] = useState<Query[] | null>(null);
    const [hasChecked, setChecked] = useState(false);


    const getQuery = (index) => {
        if (!queries) {
            return null;
        }
        return queries[index];
    };

    const refresh = () => {
        getSavedQueries().then(saved => {
            console.log('returned from api', saved)
            setQueries(saved);
            setChecked(true);
        })
    }

    useEffect(refresh, []);

    refreshQueries.current = refresh;

    const getQueryOptions = () => {
        if(!queries) {
            return [{label: 'Loading...', value: '-1'}];
        }
        else if(queries.length === 0 && hasChecked) {
            return [{label: 'No Saved Queries', value: '-1'}]
        }
        const options = queries.map<any>((v, i) => {return{label: v.name, value: i}});
        if(!options) {
            return [{label: 'Loading...', value: '-1'}];
        }

        const retOps = [{label: 'Choose a template', value: '-1'}];
        retOps.push(...options);

        return retOps;
    };

    const onSavedLoaded = (e) => {
        setValue(e.target.value)
        const query = getQuery(Number.parseInt(e.target.value));
        if(query == null) {
            return;
        }
        onQueryChanged(query);
    }

    return (
        <>
            <Select options={getQueryOptions()} onChange={onSavedLoaded} value={value}/>
            <Space value={20} />
        </>
    );
};

export default SavedQueries;
