import { useState } from "react";
import ReactDOM from "react-dom";
import DeleteModal from "./DeleteModal";
import Notification from "../../UI/Notification/Notification";

import useTimeAgo from "../../../hooks/useTimeAgo";
import useNotification from "../../../hooks/useNotification";
import { deleteFile, getFiles, getFile } from "../../../api/api";
import { downloadFile } from "../../../assets/helper";

import SpreadSheet from "../../../assets/svg/SpreadSheet";
import classes from "./Files.module.scss";

const FileItem = ({ item, setFilesList }) => {
  const time = useTimeAgo(item.uploaded);

  const [showModal, setShowModal] = useState(false);

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const fetchData = () => {
    getFiles(1, 6)
      .then((response) => {
        setFilesList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const deleteFileHandler = () => {
    removeNotification();

    deleteFile(item.id)
      .then((response) => {
        fetchData();
        closeModalHandler();

        addNotification({
          status: "success",
          text: response.message,
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      })
      .catch((error) => {
        console.error(error);

        addNotification({
          status: "error",
          text: "Error deleting. Please try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  const downloadHandler = () => {
    removeNotification();

    let downloaded = getFile(item.id);

    downloadFile(atob(downloaded.base64), downloaded.name);
  };
/* | id: {item.id} */
  return (
    <>
      <div className={classes.File}>
        <div className={classes.File__icon}>
          <SpreadSheet />
        </div>
        <div>
          <p className={classes.File__text}>{item.name} </p>
          <p className={classes.File__text}>
            {item.uploaded_by} uploaded {time}
          </p>
          <div>
            <span className="link" onClick={downloadHandler}>
              download file
            </span>{" "}
            |{" "}
            <span className="link" onClick={openModalHandler}>
              delete
            </span>            
          </div>
        </div>
      </div>

      {showModal &&
        ReactDOM.createPortal(
          <DeleteModal
            onClose={closeModalHandler}
            onDelete={deleteFileHandler}
            fileName={item.name}
          />,
          document.querySelector("body")
        )}

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={() => removeNotification()}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default FileItem;
