import { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import Banner from "../components/Banner/Banner";
import Space from "../components/UI/Space/Space";
import Card from "../components/Card/Card";
import Files from "../components/FileUpload/Files/Files";
import DropZone from "../components/FileUpload/DropZone/DropZone";

import { getFiles } from "../api/api";

import BannerImg from "../assets/images/upload_banner.png";
import classes from "./pages.module.scss";

const FileUpload = () => {
  const [filesList, setFilesList] = useState(null);

  const fetchData = () => {
    getFiles(1, 3)
      .then((response) => {
        setFilesList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Banner
        image={BannerImg}
        title="Secure"
        subtitle="Upload"
        description="Secure file sharing with Community Financial Resources"
      />

      <Space value="70" />

      <div className="container">
        <div className={`${classes.Row} ${classes.FileUpload__row}`}>
          <div className={classes.FileUpload__left}>
            <Card>
              <h2 className={classes.Title}>Your files</h2>

              {filesList && <Files filesList={filesList} setFilesList={setFilesList} id="fileList" />}
            </Card>
          </div>

          <div className={classes.FileUpload__right}>
            <Card>
              <h2 className={classes.Title}>Upload data</h2>
              <p className={classes.FileUpload__text}>
                Drag files to the dotted space below or click “Choose File” to
                select your file.
              </p>

              <DropZone onRender={fetchData} />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FileUpload;
