import { useEffect, useState, useRef } from "react";
import Modal from "../UI/Modal/Modal";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";
import BlankProfile from "../../assets/images/blank_profile.png"
import { ErrorMessageProviderBuilder } from "../UI/Input/ErrorMessageProviderBuilder.ts"

import useInput from "../../hooks/useInput";
import classes from "./ProfileModal.module.scss";
import { updatePassword, uploadAndCompressProfile, uploadProfileWithoutCompression } from "../../api/api";
import { updateMe, getUserProfilePicture } from "../../api/api";

const ProfileModal = ({ onClose, data }) => {
  const [image, setImage] = useState(null);
  const inputFile = useRef()
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { name, phone, email } = data || {name: null, phone: {phone_number: null}, email: null};


  const phoneMessageProvider = new ErrorMessageProviderBuilder('Phone Number').isUpTo(20);

  const {
    value: enteredPhone,
    isTouched: phoneTouched,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    inputChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => {
    return value.trim() === '' || phoneMessageProvider.passes(value)
  } , phone.phone_number || "");

  const {
    value: enteredName,
    isValid: nameIsValid,
    hasError: nameHasError,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput((value) => value.trim() !== "", name || "");

  const {
    value: enteredEmail,
    isTouched: emailTouched,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.trim() !== "", email || "");


  const updateProfileHandler = (prepareBody) => {
    updateMe(prepareBody)
      .then(() => {
        onClose();

        // addNotification({
        //   status: "success",
        //   text: "Your profile has been updated.",
        //   btn_text: "OK",
        // });

        if(window.confirm(
          `Your profile has been updated.  The page will now refresh with your updated profile.  If your email address has changed, you will need to sign in again.`
        )) {
          window.location.reload(false);
        }

        // setTimeout(() => {
        //   removeNotification();
        // }, 10000);
      })
      .catch((error) => {
        // window.location.reload(false);
        var text =
          error.toString() ||
          "Error saving profile updates. Please check your inputs and try again.";
        alert(`${text}`);
        // addNotification({
        //   status: "error",
        //   text,
        //   btn_text: "OK",
        // });

        // setTimeout(() => {
        //   removeNotification();
        // }, 10000);
      });
  };

  let formIsValid = true;

  const saveHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setFormSubmitting(true);

    const prepareBody = {
      name: enteredName,
      phone_number: enteredPhone,
      email: enteredEmail,
    };

    updateProfileHandler(prepareBody)
    setFormSubmitting(false);
  };

  const resetPassword = (event) => {
    event.preventDefault();
    setFormSubmitting(true);

    updatePassword()
      .then(() => {
        onClose();
        alert("We just sent you an email to reset your password.");
        setFormSubmitting(false);

        // addNotification({
        //   status: "success",
        //   text: "We've just sent you an email to reset your password.",
        //   btn_text: "OK",
        // });

        // setTimeout(() => {
        //   removeNotification();
        // }, 10000);
      })
      .catch((error) => {
        setFormSubmitting(false);
        onClose();
        var text =
          error.toString() ||
          "We were unable to reset your password.  Please try again later or log out and use the 'forgot passport' link on the login screen.";
        text = text.replace("Error: ", "");
        window.confirm(text);
        // addNotification({
        //   status: "error",
        //   text,
        //   btn_text: "OK",
        // });

        // setTimeout(() => {
        //   removeNotification();
        // }, 10000);
      });
  };

  useEffect(() => {
    async function getPfp() {
      let pfp = BlankProfile;
      try {
        pfp = await getUserProfilePicture(data.id);
      } catch {}
      setImage(pfp);
      console.log(pfp);
    }
    getPfp();
  });

  const selectProfilePictureDialoge = () => {
    inputFile.current.click();
  }
  const onProfilePictureSelected = (event) => {
    
    const file = event.target.files[0]
    if(!file) {
      return;
    }
    if(!file.type.startsWith("image")) {
      return;
    }

    if(['image/png', 'image/jpeg'].includes(file.type)) {
      uploadAndCompressProfile(event);
    }else {
      uploadProfileWithoutCompression(file);
    }
  }

  if(!data) {
    return (<Modal title="My Profile" onClose={onClose}>
      <p>Profile Loading.<br/>Please Wait</p>
    </Modal>)
  }

  return (
    <Modal title="My Profile" onClose={onClose}>
      <form onSubmit={saveHandler}>
        <div className={classes.Modal__col__100}>
          <div className={classes.Modal__col__100}>
            <Input
              label="Name:"
              clsType="small"
              required={true}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
              value={enteredName}
              hasError={nameHasError}
              touched={false}
              isValid={nameIsValid}
            />
          </div>

          {image == null ? (
            <div className={classes.invisible_image}></div>
          ) : (
            <div className={classes.Modal__img} onClick={selectProfilePictureDialoge}>
              <input type='file' accept="image/*" id='file' ref={inputFile} style={{display: 'none'}} onChange={onProfilePictureSelected}/>
              <img src={image} alt="avatar" />
            </div>
          )}
        </div>

        <Space value="20" />

        <Input
          label="Email:"
          clsType="small"
          required={true}
          value={enteredEmail}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          hasError={emailHasError}
          touched={emailTouched}
          isValid={emailIsValid}
        />

        <Input
          label="Phone:"
          clsType="small"
          required={false}
          onChange={phoneChangeHandler}
          onBlur={phoneBlurHandler}
          value={enteredPhone}
          hasError={phoneHasError}
          touched={phoneTouched}
          isValid={phoneIsValid}
          errorMessageProvider={phoneMessageProvider.build()}
          maxLength={20}
        />

        <Space value="30" />

        <div className={classes.Modal__password}>
          <Button style={classes.Modal__password} onClick={resetPassword}>
            Click here to change your password.
          </Button>
        </div>

        <Space value="30" />

        <div className={classes.Modal__center}>
          <Button
            color="primary"
            type="submit"
            disabled={!formIsValid || formSubmitting}
            onClick={saveHandler}
          >
            Save Changes
          </Button>
        </div>

        {formSubmitting && (
          <div className={classes.Modal__pleaseWait}>
            Please wait while we process your request.
          </div>
        )}
      </form>
    </Modal>
  );
};

export default ProfileModal;
