import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import Card from "../components/Card/Card";
import Space from "../components/UI/Space/Space";

import classes from "./pages.module.scss";

const Help = () => {
  return (
    <Layout>
      <Heading
        title="Help"
        description="A quick overview of your enrollment portal."
      />

      <Space value="50" />

      <div className="container">
        <div className={`${classes.Row} ${classes.Enroll__row}`}>
          <div className={classes.Enroll__left}>
            <Card>
              <h4>Overview of Sections</h4>
              <p>Let's navigate through the different parts of the enrollment portal to make sure you are familiar with all the features.  
              Of course, if you have any questions, please feel free to <a href="mailto:support@cfrcard.org">contact us</a> any time!</p>
              <Space value="25" />
              <h4>Dashboard</h4>
              <p>The Dashboard is the default page when you first visit the enrollment portal.  It provides a bird's eye view of the
              enrollments for your location or organization, and lets you double check the contact information.
              </p>
              <Space value="25" />
            </Card>
          </div>

          <div className={classes.Enroll__right}>
            <Card>
              <h2 className={classes.Enroll__subtitle}>Required Fields</h2>

              <p className={classes.Enroll__text}>
                Please complete each section of the enrollment wizard carefully
                to ensure accuracy. Fields with a * indicator are required.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Form Security</h2>

              <p className={classes.Enroll__text}>
                This information is sent using standard browser encryption.
                Click the security seal at the bottom of the page for details.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Post Office Boxes</h2>

              <p className={classes.Enroll__text}>
                If the applicant has a Post Office Box as a mailing address,
                please make sure your provide their physical address first and
                check the box to provide their Post Office Box as their mailing
                address.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Help;
