import classes from "./Input.module.scss";
import { MdHelp, MdCheckCircle } from "react-icons/md";
import Calendar from "../../../assets/svg/Calendar";
import { useRef } from "react";
import { IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

function setIcon(props) {
  if (props.type === "date") {
    return (
      <span className={`${classes.Icon} ${classes.Icon__date}`}>
        <Calendar />
      </span>
    );
  }

  if (!props.touched) {
    return null;
  }

  if (props.hasError) {
    return <ErrorIcon />;
  } else {
    return <SuccessIcon />;
  }
}

const ErrorIcon = () => {
  return (
    <span className={`${classes.Icon} ${classes.Icon__error}`}>
      <MdHelp />
    </span>
  );
};

const SuccessIcon = () => {
  return (
    <span className={`${classes.Icon} ${classes.Icon__success}`}>
      <MdCheckCircle />
    </span>
  );
};

const Input = (props) => {
  const input = useRef(null)
  const inputType = props.maxLength ? 'text' : props.type || "text";
  const cls = [classes.Input, props.clsType ? classes[props.clsType] : ""];
  const htmlFor = `${inputType}-${Math.random()}`;

  if (props.hasError) {
    cls.push(classes.invalid);
  }

  const icon = setIcon(props);


  return (
    <div className={cls.join(" ")}>
      {props.label && (
        <label htmlFor={htmlFor}>
          {props.label}{" "}
          {props.hasError ? (
            <span className={classes.Input__error}>
              {props.errorMessageProvider ? props.errorMessageProvider(input.current) : props.errorMessage || "Enter valid data"}
            </span>
          ) : null}
        </label>
      )}
      <input
        type={inputType}
        id={htmlFor}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        required={props.required}
        role="presentation"
        autoComplete="off"
        ref={input}
        maxLength={props.maxLength || 524288} //https://www.dofactory.com/html/maxlength
      />

      {icon}
      {props.cancel && (
        <IconButton className={classes.Icon__cancel} aria-label="cancel" onClick={props.cancel}>
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Input;
