import classes from "./Button.module.scss";

/* 
    color = [primary, secondary]
*/

const Button = (props) => {
  const cls = [
    classes.Button,
    props.color ? classes[props.color] : classes.primary,
    props.clsType ? classes[props.clsType] : ""
  ];

  const type = props.type || "button";

  return (
    <button
      type={type}
      onClick={props.onClick}
      className={cls.join(" ")}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
