//import * as React from 'react';
import { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useQuery } from '@tanstack/react-query';
import { IconButton, Box } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useNavigate } from 'react-router-dom';

// const handleBulkActionCancel = () => {
//   console.log("Bulk Action Cancel Clicked")
// }

const CommonTable = ({ columns, fetch, bodyParams, entryPath }) => {

  const navigate = useNavigate()

  //const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  

  const { data, isError, isFetching, isLoading } = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      bodyParams
    ],
    queryFn: () => { return fetch(bodyParams, sorting, pagination) },
    keepPreviousData: true,
  })


  return (
    <div className="container">
      {!data?.items.length ? (
        <p>No data</p>
      ) : (
          <>
            {/* <div className={classes.Table__info}>
              Showing {pagination.pageSize * pagination.pageIndex + 1} to {(pagination.pageSize * pagination.pageIndex) + Number(data?.summary.items_returned)} of{" "}
              {data?.summary.total_items} enrollments.
            </div> */}

            {/* <Select
              placeholder={"Bulk Actions"}
              components={{ Option: CustomOption }}
              controlShouldRenderValue={false}
              menuPlacement="bottom"
              name={"Bulk Actions"}
              closeMenuOnSelect={false}
              options={[
                {
                  value: 1,
                  label: 'Cancel'
                }
              ]}
            /> */}
            {/* { Object.keys(rowSelection).length === 0 ? null :
              <>
                <Accordion className={classes.Bulk_Actions__Accordion}>
                  <Accordion.Item className={classes.Bulk_Actions__Accordion_Item} eventKey="0">
                    <Accordion.Header className={classes.Bulk_Actions__Accordion_Item_Header}>Bulk Actions</Accordion.Header>
                    <Accordion.Body>
                      <div style={{cursor:'pointer'}} onClick={handleBulkActionCancel} className={classes.Bulk_Actions__Accordion_Item_Button}>
                        Cancel
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            } */}

            <Box sx={{borderRadius: '10px', border: 'solid 1px #e0e0e0', padding: '5px 0 15px 0'}}>
              <MaterialReactTable
                columns={columns}
                data={data?.items ?? []}
                initialState={{ density: 'compact'}}
                manualPagination
                manualSorting
                enableColumnResizing
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    border: 'none',
                  },
                }}              
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontSize: '14px',
                    fontFamily: 'Avenir',
                    fontWeight: 500,
                    opacity: .87,
                    background: '#F6F6F6',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: 'Avenir',
                    fontWeight: 350,
                    opacity: 1,
                    letterSpacing: '0.25px',
                  },
                }}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.summary?.total_items ?? 0}
                state={{
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBar: isFetching,
                  sorting,
                  //rowSelection,
                }}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={({ row }) => [
                  <Box display="flex">
                    <IconButton onClick={(event) => {event.stopPropagation(); console.info('Edit')}}>
                      <ModeEditOutlinedIcon />
                    </IconButton>
                  </Box>
                ]}
                //enableRowSelection
                //onRowSelectionChange={setRowSelection}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableTopToolbar={true}
                enableColumnDragging={false}
                enableColumnOrdering={true}
                muiTablePaginationProps={{
                  showFirstButton: true,
                  showLastButton: true,
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    navigate(entryPath + data.items[row.id].action_id)
                  },
                  sx: {
                    cursor:'pointer'
                  }
                })}
              />
            </Box>


          </>
      )}
    </div>
  );
};

export default CommonTable;
