import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSearchParams } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import SearchForm from "../components/Search/SearchForm";
import SearchTable from "../components/Search/SearchTable";
import Notification from "../components/UI/Notification/Notification";

import useNotification from "../hooks/useNotification";
import { getEnrollments, getLocations } from "../api/api";

let isInitial = true;

const Search = () => {
  let [searchParams] = useSearchParams();
  const params = {};

  searchParams.forEach((key, value) => {
    params[value] = key;
  });

  const [bodyParams, setBodyParams] = useState({});
  const [location, setLocation] = useState([]);
  const [enrollments, setEnrollments] = useState(null);

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const closeNotificationHandler = () => {
    removeNotification();
    onSearch(bodyParams);
  };

  useEffect(() => {
    getLocations()
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onSearch = (body) => {
    setEnrollments(null);
    setBodyParams(body);

    getEnrollments(body)
      .then((response) => {
        setEnrollments(response);
      })
      .catch((error) => {
        console.error(error);
        setEnrollments(null);

        addNotification({
          status: "error",
          text: "Error loading enrollment details.  Please check your inputs and try again.",
          btn_text: "RETRY",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  useEffect(() => {
    if (isInitial && Object.keys(params).length) {
      isInitial = false;

      onSearch(params);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Heading
          title="Search"
          description="Find previous enrollments and get additional details."
        />
        <div style={{minHeight: 70 + 'vh'}}>

          <SearchForm
            enrollments={enrollments}
            searchParams={params}
            onSearch={onSearch}
            location={location}
          />

          {enrollments && (
            <SearchTable enrollments={enrollments} bodyParams={bodyParams}/>
          )}
        </div>
      </Layout>

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={closeNotificationHandler}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default Search;
