import classes from "./Checkbox.module.scss";

const Checkbox = ({label, value, onChange}) => {
  return (
    <div className={classes.Checkbox}>
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            { label ? <span>{label}</span> : null}
        </label>
    </div>
  )
}

export default Checkbox