/**
 * Phone Number Format
 */
export const phoneNumberFormat = (value) => {
  if (!value) return value;

  value = value.toString();

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) {
    return phoneNumber;
  } else if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
};

/**
 * Zip Code Format
 */
export const zipCodeFormat = (value) => {
  if (!value) return value;

  value = value.toString();

  const zipNumber = value.replace(/[^\d]/g, "");
  const zipNumberLength = zipNumber.length;

  if (zipNumberLength <= 5) {
    return zipNumber;
  } else {
    return `${zipNumber.slice(0, 5)}-${zipNumber.slice(5, 9)}`;
  }
};

/**
 * Download CSV File
 */
export const downloadFile = (data, name) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.csv`);
  a.click();
};

/**
 * Create CSV from JSON
 */
export const csvMaker = (data) => {
  const csvRows = [];

  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));

  data.forEach((item) => {
    const values = Object.values(item).join(",");
    csvRows.push(values);
  });

  return csvRows.join("\n");
};

/**
 * Set Date Format from timestamp
 */
export const setDateFormat = (timestamp, params) => {
  const date = new Date(timestamp * 1000);

  const defaultParams = {
    month: "numeric",
    year: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formatParams = params ? params : defaultParams;

  const formatedDate = date.toLocaleString("en-US", formatParams);

  return formatedDate.replace(/,/g, "");
};


/**
 * Scroll to top
 */
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}