import imageCompression from 'browser-image-compression';
import { getHeaders, getBaseUrl } from './common';

const BASE_URL = getBaseUrl();

/**
 * Get activity
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns most recent activity for the organization or location
 */
export const getActivity = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/activity?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get CBO
 * @returns - Returns cbo-level information, like helpful information and important links.
 */
export const getCbo = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cbo`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Enrollments
 * @param {*} params: {
 * perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * page - (Integer), he page number, starting with 1, default is 1
 * location_id - (String), only include results from this provided location id
 * first - (String), First name of the enrollee
 * last - (String), Last name of the enrollee
 * id - (String), id for the enrollment
 * dob - (Date), Date of birth in the format YYYY-MM-DD
 * start_date - (Date), Earliest date to include in the format YYYY-MM-DD
 * end_date - (Date), Latest date to include in the format YYYY-MM-DD. This date assumes end of day for this date.
 * sortBy - (String), Field the data should be sorted by
 * sortDirection - (String), Direction the data should be sorted by
 * }
 *
 * @returns - Returns enrollments the user has access to
 */
export const getEnrollments = async (params) => {
  const perPage = params && params.perPage ? params.perPage : 6;
  const page = params && params.page ? params.page : 1;
  const location_id =
    params && params.location_id ? `&location_id=${params.location_id}` : "";
  const first = params && params.first ? `&first=${params.first}` : "";
  const last = params && params.last ? `&last=${params.last}` : "";
  const id = params && params.id ? `&id=${params.id}` : "";
  const dob = params && params.dob ? `&dob=${params.dob}` : "";
  const start_date =
    params && params.start_date ? `&start_date=${params.start_date}` : "";
  const end_date =
    params && params.end_date ? `&end_date=${params.end_date}` : "";
  const sortBy = params && params.sortBy ? `&sortBy=${params.sortBy}` : "";
  const sortDirection =
    params && params.sortDirection
      ? `&sortDirection=${params.sortDirection}`
      : "";

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/enrollments?page=${page}&perPage=${perPage}${location_id}${first}${last}${id}${dob}${start_date}${end_date}${sortBy}${sortDirection}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Enrollment by Id
 * @param {*} id - (Srting), id of the enrollment
 * @returns
 */
export const getEnrollmentsId = async (id) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/enrollment/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Enrollments Export
 * @param {*} params: {
 * perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * page - (Integer), he page number, starting with 1, default is 1
 * location_id - (String), only include results from this provided location id
 * first - (String), First name of the enrollee
 * last - (String), Last name of the enrollee
 * id - (String), id for the enrollment
 * dob - (Date), Date of birth in the format YYYY-MM-DD
 * sortBy - (String), Field the data should be sorted by
 * sortDirection - (String), Direction the data should be sorted by
 * }
 *
 * @returns - Returns enrollments the user has access to
 */
export const getEnrollmentsExport = async (params) => {

  console.log(params)
  const location_id =
    params && params.location_id ? `&location_id=${params.location_id}` : "";
  const first = params && params.first ? `&first=${params.first}` : "";
  const last = params && params.last ? `&last=${params.last}` : "";
  const id = params && params.id ? `&id=${params.id}` : "";
  const dob = params && params.dob ? `&dob=${params.dob}` : "";
  const start_date =
    params && params.start_date ? `&start_date=${params.start_date}` : "";
  const end_date =
    params && params.end_date ? `&end_date=${params.end_date}` : "";
  const sortBy = params && params.sortBy ? `&sortBy=${params.sortBy}` : "";
  const sortDirection =
    params && params.sortDirection
      ? `&sortDirection=${params.sortDirection}`
      : "";

  // much needed refactor
  // refactor koken
  /*
  const queryParams = new URLSearchParams();
  for (const key of [
    "location_id",
    "first",
    "last",
    "id",
    "dob",
    "start_date",
    "end_date",
    "sortBy",
    "sortDirection",
    "cbo_id"
  ]) {
    if (params && params[key]) {
      queryParams.append(key, params[key]);
    }
  }
  */

  const options = {
    headers: await getHeaders(),
  };

//  const url = `${BASE_URL}/enrollments/export?${queryParams.toString()}`;

  const url = `${BASE_URL}/enrollments/export?${location_id}${first}${last}${id}${dob}${start_date}${end_date}${sortBy}${sortDirection}`;

  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Create Enrollments
 * @param {*} body
 * @returns Submit a debit card enrollment request to the card processor
 */
export const createEnrollments = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/enrollments`;
  const resp = await fetch(url, options).then(res => res.json().then(json => {
    if (res.status === 403) {
      if (json.reason.enrollments_disabled) {
        return Object.assign(json, { action: 'redirect' })
      } else {
        return Object.assign(json, { action: 'failure' })
      }
    } else {
      return Object.assign(json, { action: 'continue' })
    }
  }));

  // response has an ID property ONLY when successful.
  // there has been a bug where responses are flagged as successful when they shouldnt be.
  //
  // this should fix the issue temporarily until we can find out what's causing failures
  // to have a 200 status code.
  return Object.assign({req_successful: resp.status !== 500 && resp.hasOwnProperty('id')}, resp);
};

export const getEnrollStatus = async () => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/enrollments/status`;

  const status = await fetch(url, options);
  try {
    if ((await status.json()).enabled === true) {
      return true;
    }
  } catch {

  }


  return false;
}

export const getOrgInfo = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/info`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getMessageUpdateDate = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/message`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getTables = async (database) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/data/database/tables?db=${database}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const json = await resp.json();

  return json.tables;
}

export const getSavedQueries = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/data/query/saved`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const json = await resp.json();

  return json.saved;
}

export const runQuery = async (query, db) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      query,
      db
    }),
  };

  const url = `${BASE_URL}/data/database/query`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

export const saveQuery = async (sql_query, name, database) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      sql_query,
      name,
      database
    }),
  };

  const url = `${BASE_URL}/data/query/saved`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

export const getCardStatistics = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/card/usagestatistics`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getTransactionReport = async (chart) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/transactions?category=${chart}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getEnrollmentsByLocation = async (from, to) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/enrollments?to_date=${to.valueOf()}&from_date=${from.valueOf()}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return (await resp.json()).enrollments;
}

export const setOrgMessage = async (message) => {
  const options = {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify({
      message
    })
  };

  const url = `${BASE_URL}/org/message`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }
}

/**
 * Get Locations
 * @returns Returns locations associated with the user
 */
export const getLocations = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/locations`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Locations Simplified
 * @returns Returns simplified location objects associated with the user
 */
export const getLocationsSimplified = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/locations/simplified`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

/**
 * @param cbo_id the CBO id related to location
 * @returns
 */
export const getLocationsFromCBO = async (cbo_id) => {
  if (!cbo_id) {
    return null;
  }
  const options = {
    headers: await getHeaders()
  };

  const url = `${BASE_URL}/cbo/${cbo_id}/locations`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get CBOs
 * @returns Returns all CBOs
 */
export const getCBOs = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cbos`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Update Location
 * @param {*} id
 * @param {*} body : {
 * name - (String)
 * address - (String)
 * address_2 - (String)
 * city - (String)
 * state - (String)
 * zip - (Number) - length = 5
 * phone - (String)
 * }
 * @returns
 */
export const updateLocation = async (id, body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.name,
      address: {
        address: body.address,
        address_2: body.address_2,
        city: body.city,
        state: body.state,
        zip: body.zip,
      },
      phone: body.phone,
    }),
  };

  const url = `${BASE_URL}/location/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

/**
 * Get users
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of users if the user is a super coordinator
 */
export const getUsers = async (params = {}) => {
  const query = new URLSearchParams();

  if (params) {
    query.set('page', params.page || 1)
    query.set('perPage', params.perPage || 6)
    query.set('include_deactivated', params.include_deactivated || false)
    query.set('include_legacy', params.include_legacy || false)
    if (params.cbo_id) {
      query.set('cbo_id', params.cbo_id)
    }
    query.set('order_by', params.order_by || 'name')
    query.set('order_direction', params.order_direction || 'ASC')

  }

  const options = {
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/users?${query.toString()}`;
  //  console.log("gets here: " + url)
  try {
    const resp = await fetch(url, options);

    //    console.log("gets here too")

    if (!resp.ok) {
      throw new Error("Something went wrong!");
    }

    return resp.json();
  } catch (e) {
    console.error(e)
  }
  return null;
};

export const getUserProfilePicture = async (username) => {
  if (!window.pfp) {
    window.pfp = {};
  }
  if (!window.pfp[username]) {
    window.pfp[username] = 'empty';
  } else if (window.pfp !== 'empty') {
    return window.pfp[username];
  }

  const options = {
    headers: await getHeaders(),
  };



  const url = `${BASE_URL}/user/${username}`;

  const resp = await fetch(url, options);

  if (resp.status !== 200) {
    throw new Error(resp);
  }


  const picture = (await resp.json()).picture;

  window.pfp[username] = picture;

  if (picture == null) {
    return null;
  }

  return picture.replace('redesign.', 'new.');
};

/**
 * Update User
 * @param {*} id
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updateUser = async (id, body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.first,
      phone: {
        phone_number: body.phone_number,
        phone_type: body.phone_type,
      },
      email: body.email,
      //Time wasted because this was hard coded: 1.5h
      //Expected time wasted for fixing it: 0.5h
      //Likeleyhood of fixing it: 2%
      upload_enabled: body.upload_enabled
    }),
  };



  const url = `${BASE_URL}/user/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp;
};

const retry = () => {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('retryReload')) {
    window.location = params.set('retryReload', '1');
    window.location.search = params.toString();
  }
}

export const getRoles = async (safeMode = false) => {
  if (window.rolesarefound === 1) {
    return await (async () => {
      while (!window.roles)
        await new Promise(resolve => setTimeout(resolve, 100));
      // console.log('me located')
      return window.roles
    })();
  }

  window.rolesarefound = 1;

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/me/roles?${safeMode ? 'safeMode=true' : ''}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  window.roles = (await resp.json()).map(r => r.name);

  return window.roles;
}

/**
 * Get Me
 * @returns Returns profile data for the current user
 */
export const getMe = async () => {
  if (window.meIsFound === 1) {
    return await (async () => {
      while (!window.me)
        await new Promise(resolve => setTimeout(resolve, 100));
      // console.log('me located')
      return window.me
    })();
  }

  window.meIsFound = 1;

  // console.log('fetching me from database')

  const options = {
    headers: await getHeaders(),
  };
  const thisUrl = window.location.toString().split('?');

  const url = `${BASE_URL}/me`;
  let resp;

  try {
    resp = await fetch(url, options);
  } catch (e) {
    console.log(e)
  }

  if (!resp.ok) {
    console.log(resp)

    try {
      const json = await resp.json();


      if ((json.cause === 'User is deactivated' || json.cause === 'User Does not exist') && !thisUrl[0].endsWith('/notexist')) {
        window.location = '/notexist';
        return;
      }

    } catch { }

    if (thisUrl[0].endsWith('/notexist')) {
      return { roles: await getRoles(true) };
    }

    retry();
    throw new Error("Something went wrong!");
  }

  const me = await resp.json();

  me.phone.phone_number = me.phone.phone_number.trim()

  // console.log('set win me')
  window.me = me;

  return me;

};



/**
 * Update Me
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * email - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updateMe = async (body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.name,
      phone_number: body.phone_number,
      email: body.email,
    }),
  };

  const url = `${BASE_URL}/me`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * updatePassword
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * email - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updatePassword = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      action: "resetPassword"
    })
  };

  const url = `${BASE_URL}/me`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * @param {*} id
 * @param {*} action - (String)
 *
 * @returns Performs an operation for a user
 */
export const userActions = async (id, action) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      action: action,
    }),
  };

  const url = `${BASE_URL}/user/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * User Invitation
 * @param {*} body
 * @returns - Sends an invitation to a user to become a coordinator
 */
export const userInvitation = async (body) => {
  if ((!body.location_id || body.location_id === 'null') && !body.hasOwnProperty('cbo_id')) {
    throw new Error("Location ID not specified. body was: " + JSON.stringify(body))
  }

  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/users`;
  console.log('fetching')
  return await fetch(url, options).then((resp) => {
    if (!resp.ok || resp.status === 500) {
      console.log('resp not ok')
      try {
        return resp.json().then(j => {
          return { status: 'failure', response: j.response }
        });
      } catch {
        return { status: 'failure', response: { message: 'Error sending. Please check your inputs and try again.' } }
      }
    } else {
      return { status: 'success' }
    }
  })
};

/**
 * Get Files
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of all files for this user with most recent first
 */
export const getFiles = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/files?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get File
 * @param {*} id - (integer)
 * @returns
 */
export const getFile = async (id) => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/file/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }
  const blob = await resp.blob();
  const loc = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  console.log(await resp.headers.get("File-Name"));
  a.setAttribute("href", loc);
  a.setAttribute("download", await resp.headers.get("File-Name"));
  a.click();
  return;
};

export const getLogs = async (userid, page, col, direction) => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const query = new URLSearchParams()

  if (col) {
    query.set('sort', col);
  }
  if (direction) {
    query.set('direction', direction)
  }
  query.set('page', page || 1)

  const url = `${BASE_URL}/user/file/${userid}?${query.toString()}`;
  const resp = await fetch(url, options);

  return resp.json();
};

/**
 * Delete File
 * @param {*} id - (integer)
 * @returns
 */
export const deleteFile = async (id) => {
  const options = {
    method: "DELETE",
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/file/${id}`;
  const resp = await fetch(url, options);

  return await resp.json();
};

/**
 * Upload File
 * @param {*} file
 * @param {*} fileName
 * @returns Uploads a new file for the current logged in user
 */
export const uploadFile = async (files, fileName, completedCallback) => {
  const max_size = 800000; // 800kb
  const file = files[0];

  console.log(files[0]);

  if (file.size > max_size) {
    alert("File is too large (max 800KB)");
    throw new Error("File too large to be securely uploaded");
  }

  const read = (callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      callback(btoa(reader.result));
    };

    reader.readAsBinaryString(file);
  };

  return await read(async (data) => {
    if (data === false) {
      completedCallback(false);
      return;
    }

    const options = {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({
        file: data,
        fileName: fileName,
      }),
    };

    console.log(options);

    const url = `${BASE_URL}/files`;
    const resp = await fetch(url, options);

    if (!resp.ok) {
      throw new Error();
    }
    completedCallback(true);
    return await resp.json();
  });
};


/**
 * Shows a confirmation window, and if the user confirms, the page will be reloaded.
 * @param {string} message The confirmation message to be sent to the user
 */
const requestReload = (message) => {
  if (window.confirm(message)) {
    window.location.reload();
  }
}


/**
 * Upload File
 * @param {*} file
 * @returns Uploads a new file for the current logged in user
 * @see {@link uploadAndCompressProfile uploadAndCompressProfile()} for png and jpg files.
 */

export const uploadProfileWithoutCompression = async (file) => {
  console.log(file);
  const read = (callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      callback(btoa(reader.result));
    };

    reader.readAsBinaryString(file);
  };

  return read(async (data) => {
    const options = {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({
        base64: data,
        action: "updateProfile"
      }),
    };

    console.log(options);

    const url = `${BASE_URL}/me`;
    const resp = await fetch(url, options);

    if (!resp.ok) {
      throw new Error("Something went wrong!");
    }

    requestReload('To get an updated profile picture, the page must be refreshed. Reload now?');

    return await resp.json();
  });
};

/**
 * uploads profile picture to api, while also compressing it to save on space. Then reloads the page after it's done
 * @param {*} event Takes in an Input onChange Event
 * @returns nothing, and reloads the page.
 */
export const uploadAndCompressProfile = async (event) => {
  const imageFile = event.target.files[0];
  console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 0.009,
    maxWidthOrHeight: 500,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    if (!(compressedFile instanceof Blob)) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(compressedFile);
    reader.onloadend = async function () {
      const options = {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify({
          base64: reader.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
          action: "updateProfile"
        }),
      };

      console.log(options);

      const url = `${BASE_URL}/me`;
      const resp = await fetch(url, options);

      if (!resp.ok) {
        throw new Error("Something went wrong!");
      }

      requestReload('To get an updated profile picture, the page must be refreshed. Reload now?');

      // return await resp.json();
    }
  } catch (error) {
    console.log(error);
  }
}

// Notes
export const createNote = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/notes`;
  const resp = await fetch(url, options)

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return Object.assign({req_successful: resp.status !== 500}, await resp.json());
};

export const getNotes = async (entity) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/notes/${entity.type}/${entity.id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};