import { useState, useEffect } from "react";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import Card from "../components/Card/Card";
import Space from "../components/UI/Space/Space";
import EnrollForm from "../components/Enroll/EnrollForm";

import { getLocations } from "../api/api";

import classes from "./pages.module.scss";

const Enroll = () => {
  const [location, setLocation] = useState([]);

  useEffect(() => {
    getLocations()
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Layout>
      <Heading
        title="Enroll"
        description="Request a card by completing this enrollment form."
      />

      <Space value="50" />

      <div className="container">
        <div className={`${classes.Row} ${classes.Enroll__row}`}>
          <div className={classes.Enroll__left}>
            <Card>
              <EnrollForm location={location} />
            </Card>
          </div>

          <div className={classes.Enroll__right}>
            <Card>
              <h2 className={classes.Enroll__subtitle}>Required Fields</h2>

              <p className={classes.Enroll__text}>
                Please complete each section of the enrollment wizard carefully
                to ensure accuracy. Fields with a * indicator are required.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Form Security</h2>

              <p className={classes.Enroll__text}>
                This information is sent using standard browser encryption.
                Click the security seal at the bottom of the page for details.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Post Office Boxes</h2>

              <p className={classes.Enroll__text}>
                If the applicant has a Post Office Box as a mailing address,
                please make sure your provide their physical address first and
                check the box to provide their Post Office Box as their mailing
                address.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Enroll;
