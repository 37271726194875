import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import classes from "./Header.module.scss";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { useState } from "react";
import { getMe } from "../../api/api";
import {useAuth0} from "@auth0/auth0-react";
import Logo from "../Logo/Logo";
import {Accordion, Dropdown} from "react-bootstrap";

const Header = () => {
  const [processApiCall, setProcessApiCall] = useState(false);
  const {user} = useAuth0();
  const [showUserPage, setShowUserPage] = useState(localStorage.getItem('showUsers') === 'true');
  const [showAdminNav, setShowAdminNav] = useState(localStorage.getItem('isAdministrator') === 'true');
  let [navbarOpen, setNavbarOpen] = useState(false);
  const navigate = useNavigate();
  let resized;
  let _windowWidth = window.outerWidth;
  const nav = document.getElementById("header-nav");
  const overlay = document.getElementById("header-overlay");

  //Mobile Hamburger Click Handler
  const clickHandler = async () => {
    let headerHeight = document.getElementById("header").offsetHeight;

    if (!navbarOpen) {
      document.body.classList.add('no-scroll');
      nav.style.top = headerHeight + "px";
      nav.style.height = "calc(100vh - " + headerHeight + "px)";
      overlay.style.top = headerHeight + "px";
      overlay.style.height = "calc(100vh - " + headerHeight + "px)";
      window.scroll(0,0);
    } else {
      resetNav();
    }
    setNavbarOpen(!navbarOpen);
  };
  //On nav item click
  const navClickHandler = async () => {
    removeNavActiveStatus();
  };

  //Resize functionality that only fires if the width is changed not the height (this is useful on mobile with address bar hiding/showing)
  const handleResize = event => {
    removeNavActiveStatus();
  }

  function removeNavActiveStatus() {
    setNavbarOpen(navbarOpen = false);
    resetNav();
  }

  function resetNav() {
    document.body.classList.remove('no-scroll');
    setTimeout(function() {
      nav.style.removeProperty("top");
      nav.style.removeProperty("height");
      overlay.style.removeProperty("top");
      overlay.style.removeProperty("height");
    },301);
  }

  function resizeWidthOnly() {
    let currentWindowWidth =  window.outerWidth;
    if (_windowWidth !== currentWindowWidth) {
      _windowWidth = currentWindowWidth;
      return _windowWidth;
    } else {
      return false;
    }
  }

  function resizedw() {
    if (resizeWidthOnly() !== false) {
      handleResize();
    }
  }

  window.onresize = function () {
    clearTimeout(resized);
    resized = setTimeout(resizedw, 100);
  }

  const shouldShowUserPage = (me) => {
    if (['Super Coordinator', 'Administrator'].includes(me.card_enrollment_user_level)) {
      setShowUserPage(true);
      localStorage.setItem('showUsers', true)
      return;
    }
    localStorage.setItem('showUsers', false)
    setShowUserPage(false);
  }

  // const shouldShowsAdminNav = (me) => {
  //   if (['Administrator'].includes(me.card_enrollment_user_level)) {
  //     setShowAdminNav(true);
  //     localStorage.setItem('isAdministrator', "true")
  //     return;
  //   }
  //   localStorage.setItem('isAdministrator', "false")
  // }

  const checkLocalStorage = () => {
    let showUsers = localStorage.getItem('showUsers');
    let isAdmin = localStorage.getItem('isAdministrator');

    if (showUsers && isAdmin) {
      if (showUsers === "true") {
        setShowUserPage(true);
      }

      if (isAdmin === "true") {
        setShowAdminNav(true);
      }
    } else {
      setProcessApiCall(true);
    }
  }

  useEffect(() => {
    checkLocalStorage();

    if (processApiCall) {
      getMe().then((me) => {
        shouldShowUserPage(me);
        // shouldShowsAdminNav(me);
      })
    }
  }, [processApiCall]);

  return (
    <header id="header">
      { process.env.REACT_APP_ENV === "training" &&
      <div className={classes.Training}>
        <p><strong>Training Site</strong> Any information provided here is public and will be deleted daily.  Do not put any private information on this site.</p>
      </div>
      }
      { process.env.REACT_APP_ENV === "staging" &&
      <div className={classes.Development}>
        <p><strong>Staging Environment</strong> This environment is for testing purposes only.  Do not enter private information.</p>
      </div>
      }
      <div className={`container`}>
        <div className={classes.FirstRow}>
          <div className={classes.FirstRow_Left}>
            <div className={classes.FirstRow_Left_Hamburger}>
              {
                window.location.pathname !== '/notexist' &&
                <HamburgerMenu />
              }
            </div>
            <img src="https://connect.communityfinancialresources.org/images/logo.svg" alt="Community Financial Resources" className={classes.FirstRow_Left_Logo} onClick={() => {navigate("/")}} />
          </div>
          {
            window.location.pathname !== '/notexist' &&
            <div className={classes.FirstRow_Profile}>
              <Logo />
              <HamburgerMenu />
              <ProfileDropdown />
            </div>
          }
          <button className={classes.FirstRow_Hamburger + `${ navbarOpen ? " " + classes.FirstRow_Hamburger_Active : "" }`} onClick={clickHandler}>
            <span></span>
          </button>
        </div>
        <div className={classes.SecondRow}>
          <div className={classes.SecondRow_User}>
            <p className={classes.SecondRow_User_Name}>Welcome, {user.name}!</p>
            <div className={classes.SecondRow_User_Profile}>
              <ProfileDropdown />
            </div>
          </div>
          <div className={classes.Nav_Overlay + `${ navbarOpen ? " " + classes.Nav_Overlay_Active : "" }`} id="header-overlay"></div>
          <div className={classes.SecondRow_NavWrp  + `${ navbarOpen ? " " + classes.SecondRow_NavWrp_Active : "" }`} id="header-nav">
          { !['/file-upload-utility', '/notexist'].includes(window.location.pathname) &&
            <nav className={classes.Nav + ` ${showAdminNav ? classes.Admin : classes.Coord}`}>
              <NavLink exact="true" to="/" className={classes.Nav__link} onClick={navClickHandler}>
                Dashboard
              </NavLink>

              { showAdminNav ?
                  <>
                    <Dropdown className={classes.Nav__Dropdown} variant="default">
                      <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                        Enrollments
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                        <Dropdown.Item as={NavLink} to="/enroll">Enroll</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/enrollments">Search</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Accordion className={classes.Nav__Accordion}>
                      <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                        <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Enrollments</Accordion.Header>
                        <Accordion.Body>
                          <NavLink to={`/enroll`} className={classes.Nav__Accordion_Item_Link}>
                            Enroll
                          </NavLink>
                        </Accordion.Body>
                        <Accordion.Body>
                          <NavLink to={`/search`} className={classes.Nav__Accordion_Item_Link}>
                            Search
                          </NavLink>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                  :
                  <NavLink to="/enroll" className={classes.Nav__link} onClick={navClickHandler}>
                    Enroll
                  </NavLink>
              }

              { !showAdminNav &&
                  <NavLink to="/search" className={classes.Nav__link} onClick={navClickHandler}>
                    Search
                  </NavLink>
              }

              { showUserPage &&
                <NavLink to="/users" className={classes.Nav__link} onClick={navClickHandler}>
                  Users
                </NavLink>
              }

              { !showAdminNav &&
                <NavLink to="/help" className={classes.Nav__link} onClick={navClickHandler}>
                  Help
                </NavLink>
              }

              { showAdminNav &&
                  <>
                    <Dropdown className={classes.Nav__Dropdown} variant="default">
                      <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                        Organizations
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                        <Dropdown.Item>CBOs</Dropdown.Item>
                        <Dropdown.Item>Locations</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Accordion className={classes.Nav__Accordion}>
                      <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                        <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Organizations</Accordion.Header>
                        <Accordion.Body>
                          <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                            CBOs
                          </NavLink>
                        </Accordion.Body>
                        <Accordion.Body>
                          <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                            Locations
                          </NavLink>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
              }

              { showAdminNav &&
                  <>
                  <Dropdown className={classes.Nav__Dropdown} variant="default">
                    <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                      Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                      <Dropdown.Item as={NavLink} to="/query-tool">Query Tool</Dropdown.Item>
                      <Dropdown.Item>Saved Queries</Dropdown.Item>
                      <Dropdown.Item>Import</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Accordion className={classes.Nav__Accordion}>
                    <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                      <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Data</Accordion.Header>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Query Tool
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Saved Queries
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Import
                        </NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              }

              { showAdminNav &&
                <>
                  <Dropdown className={classes.Nav__Dropdown} variant="default">
                    <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                      Reports
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                      <Dropdown.Item>Enrollment</Dropdown.Item>
                      <Dropdown.Item>Card Types</Dropdown.Item>
                      <Dropdown.Item>Processing</Dropdown.Item>
                      <Dropdown.Item>Account Export</Dropdown.Item>
                      <Dropdown.Item>Cashflow</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Accordion className={classes.Nav__Accordion}>
                    <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                      <Accordion.Header className={classes.Nav__Accordion_Item_Header}><span>Reports</span></Accordion.Header>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Enrollment
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Card Types
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Processing
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Account Export
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Cashflow
                        </NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              }
            </nav>
          }
          </div>
        </div>
      </div>
    </header>
    )


  ;
};

export default Header;
