import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSearchParams } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import EnrollmentFilters from "../components/List/Enrollment/Filters";
import EnrollmentTable from "../components/List/Enrollment/Table";
import Notification from "../components/UI/Notification/Notification";

import useNotification from "../hooks/useNotification";
import { getLocationsSimplified, getCBOs } from "../api/api";
import useInput from "../hooks/useInput";

let isInitial = true;

const EnrollmentSearch = () => {

  const filterValues = {}

  const [filterState, setFilter] = useState(
    {
      "dob": {
        label: 'Date of Birth',
        active: false,
        type: 'date',
        paramName: 'dob',
        placeholder: 'MM/DD/YYYY'
      },
      "location_id": {
        label: "Location ID",
        active: false,
        type: 'string',
        paramName: "loc_id",
        placeholder: "CSFW01"
      },
    }
  )

  filterValues["dob"] = useInput(() => true)
  filterValues["location_id"] = useInput(() => true)

  const setFilterState = (filter) => {
    setFilter({...filter})
  }

  let [searchParams] = useSearchParams();
  const params = {};

  searchParams.forEach((key, value) => {
    params[value] = key;
  });

  const [bodyParams, setBodyParams] = useState({});
  const [location, setLocation] = useState([]);
  const [cbo, setCbo] = useState([])

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    //add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const closeNotificationHandler = () => {
    removeNotification();
    onSearch(bodyParams);
  };

  useEffect(() => {
    getLocationsSimplified()
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.error(error);
      });

    getCBOs()
      .then((response) => {
        setCbo(response);
      })
      .catch((error) => {
        console.error(error)
      })
  }, []);

  const onSearch = (body) => {
    setBodyParams(body);
  };

  useEffect(() => {
    if (isInitial && Object.keys(params).length) {
      isInitial = false;

      onSearch(params);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Heading
          title="Search"
          description="Find previous enrollments and get additional details."
        />
        <div style={{minHeight: 70 + 'vh'}}>
          <EnrollmentFilters
            searchParams={params}
            onSearch={onSearch}
            location={location}
            cbo={cbo}
            filterState={filterState}
            setFilterState={setFilterState}
            filterValues={filterValues}
          />
          <EnrollmentTable
            bodyParams={bodyParams}
          />
        </div>
      </Layout>

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={closeNotificationHandler}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default EnrollmentSearch;
