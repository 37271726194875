import Cancel from "../../assets/svg/Cancel";
import Failure from "../../assets/svg/Failure";
import Accepted from "../../assets/svg/Accepted";
import New from "../../assets/svg/New";

import classes from "./EnrollmentDetail.module.scss";

const setDateFormat = (timestamp) => {
  const date = new Date(timestamp * 1000);

  const formatedDate = date.toLocaleString("en-US", {
    month: "short",
    year: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  });

  return formatedDate.replace(/,/g, "");
};

const HistoryItem = ({ item }) => {
  const time = setDateFormat(item.timestamp);
  const { user } = item;

  const Icon = () => {
    switch (item.status) {
      case "new":
        return <New />;

      case "accepted":
        return <Accepted />;

      case "failure":
        return <Failure />;

      case "cancel":
        return <Cancel />;

      default:
        return null;
    }
  };

  const Status = () => {
    switch (item.status) {
      case "new":
        return "New";

      case "accepted":
        return "Accepted";

      case "failure":
        return "Failure";

      case "cancel":
        return "Cancel";

      default:
        return null;
    }
  };

  return (
    <div className={classes.History__row}>
      <div className={classes.History__icon}>
        <Icon />
      </div>
      <div className={classes.History__info}>
        <p>
          <b><Status /></b> on {time}
        </p>
        <p>
          User:{" "}
          <a href={`mailto:${user.email}`} className={classes.History__link}>
            {user.first} {user.last}
          </a>
        </p>
      </div>
    </div>
  );
};

export default HistoryItem;
