import classes from "../../List.module.scss";
import Input from "../../../UI/Input/Input";

const DynamicFilterInputs = ({filterState, setFilterState, resets, filterValues}) => {

    return (
        <form className={classes.Form__dynamic__inputs}>
          {Object.keys(filterState).map((key) => (filterState[key].active) ? (
            <div className={classes.Form__col}>
              <div className={classes.Form__input__long}>
                <Input
                  key={key}
                  type={filterState[key].type}
                  label={filterState[key].label + ":"}
                  clsType="small"
                  value={filterValues[key].value}
                  onChange={filterValues[key].inputChangeHandler}
                  placeholder={filterState[key].placeholder}
                  cancel={() => {
                    var newFilterState = {...filterState}
                    newFilterState[key].active = false
                    setFilterState(newFilterState)
                  }}
                />
            </div>
        </div>) : null)}
      </form>
    )
}

export default DynamicFilterInputs;
