import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading";
import Space from "../components/UI/Space/Space";

import Details from "../components/EnrollmentDetail/Details";
import History from "../components/EnrollmentDetail/History";
import Notes from "../components/EnrollmentDetail/Notes";
import StatusCodeModal from "../components/EnrollmentDetail/StatusCodeModal";

import { getEnrollmentsId, getMe } from "../api/api";

import Info from "../assets/svg/Info";
import classes from "./pages.module.scss";

const EnrollmentDetail = () => {
  const [enrollment, setEnrollment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const params = useParams();

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getEnrollmentsId(params.id)
      .then((response) => {
        setEnrollment(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [params.id]);

  if (!activeUser) getMe().then((me) => {
    setActiveUser(me)
  });

  return (
    <>
      <Layout>
        <Heading
            title="Enrollment"
            description="Details about this enrollment"
          />
        <Space value="50" />

        <div className={classes.EnrollDetail}>
          <div className="container">
            <div className={classes.Row}>
              <div className={classes.EnrollDetail__left}>
                <Details enrollment={enrollment} />
              </div>

              <div className={classes.EnrollDetail__right}>
                <div className={classes.History}>
                  <div className={classes.EnrollDetail__title}>
                    <span>Enrollment History</span>
                    <span
                      onClick={openModalHandler}
                      className={`${classes.EnrollDetail__icon} ${classes.EnrollDetail__icon__info}`}
                    >
                      <Info />
                    </span>
                  </div>

                  <History enrollment={enrollment} />
                </div>

                {activeUser && activeUser.card_enrollment_user_level === "Administrator" && (
                  <div className={classes.Notes}>
                    <div className={`${classes.EnrollDetail__title} ${classes.Notes__title}`}>
                      <p>Internal Notes</p>
                    </div>
                    <Notes enrollment={enrollment} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {showModal &&
        ReactDOM.createPortal(
          <StatusCodeModal onClose={closeModalHandler} />,
          document.querySelector("body")
        )}
    </>
  );
};

export default EnrollmentDetail;
